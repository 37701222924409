import React, { useState, useContext, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "../../assets/css/tasklist.scss";
import { AppContext } from "../../context/AppContext";
import "../../assets/css/tasklist.scss";
import {
  PostCallWithErrorResponse,
  simpleGetCallWithToken,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { ToastMsg } from "../TostMsg";
import Loader from "../Loader";
import { getDateddmmyyyy } from "../../common";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";

import searchicon from "../../assets/images/ic_search.svg";
// import { simpleGetCallWithToken } from "../../api/ApiServices";

const LeaveApprove = () => {
  const { sidebar, setSidebar, Dark, setDark, userData, permissions } =
    useContext(AppContext);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [hide, setHide] = useState(true);
  const handleShow = () => setShow(true);
  const handleHide = () => setHide(false);
  const [approveList, setApproveList] = useState([]);
  const [subapproveList, setSubApproveList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [subListLoader, setSubListLoader] = useState(false);
  const [isReject, setIsReject] = useState(false);

  const [searchVal, setSearchVal] = useState("");

  const [selectedLeaves, setSelectedLeaves] = useState({
    approvals: [],
  });
  console.log("selectedLeaves,", selectedLeaves);
  useEffect(() => {
    getApproveListApi();
  }, []);

  // const getApproveListApi = () => {
  //   setLoading(true);
  //   PostCallWithErrorResponse(
  //     ApiConfig.LEAVEAPROVE_LIST,
  //     JSON.stringify({
  //       OrgId: Number(userData.OrgId),
  //     })
  //   )
  //     .then((res) => {
  //       setLoading(false);
  //       setApproveList(res.json.Data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  const getApproveListApi = () => {
    setLoading(true);
    simpleGetCallWithToken(ApiConfig.LEAVEAPROVE_LIST)
      .then((res) => {
        if (res.Success === true) {
          setLoading(false);
          setApproveList(res?.Data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getApproveSubList = (LeaveApplicationId) => {
    setSubListLoader(true);
    PostCallWithErrorResponse(
      ApiConfig.GET_APPROVE_SUBlIST,
      JSON.stringify({
        leaveId: LeaveApplicationId,
        OrgId: Number(userData.OrgId),
      })
    )
      .then((res) => {
        console.log(res);
        if (res.json.Success) {
          setSubApproveList(res.json.Data);
          setSubListLoader(false);
        } else {
          setSubListLoader(false);
        }
      })
      .catch((err) => {
        setSubListLoader(false);
        console.log(err);
      });
  };

  const approveAndRejectAPI = (numberOfLeave, data) => {
    if (selectedLeaves.approvals.length === 0) {
      ToastMsg("error", "Please select leave");
    } else {
      setButtonLoader(true);
      let payLoadData = numberOfLeave > 1 ? selectedLeaves : data;
      PostCallWithErrorResponse(
        ApiConfig.UPDATE_APPRPVELEAVE_LIST,
        JSON.stringify(selectedLeaves)
      )
        .then((res) => {
          if (res.json.Success === true) {
            setButtonLoader(false);
            ToastMsg("success", res.json.Message);
            handleClose();
          } else {
            ToastMsg("error", res.json.Message);
            setButtonLoader(false);
          }
          getApproveListApi();
        })
        .catch((err) => {
          setButtonLoader(false);
          console.log(err);
        });
    }
  };

  const btnLoader = (
    <div
      class="spinner-border text-light"
      role="status"
      style={{ width: "1.3rem", height: "1.3rem" }}
    ></div>
  );

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role ">
            <h6 className="mt-2">Leave Approve</h6>
          </div>

          <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary">
            <div className="role-table-header">
              <div className="search-input-wrapper  mb-2 ">
                <input
                  type="text"
                  placeholder="Search Name"
                  value={searchVal}
                  onChange={(e) => setSearchVal(e.target.value)}
                />
                {Dark === "lightMode" ? (
                  <img src={searchicon} alt="" />
                ) : (
                  <img src={ic_search_dark} alt="" />
                )}
              </div>
            </div>
          </div>

          <div className="table-wrapper table-wrapper-2">
            {loading ? (
              <Loader />
            ) : (
              <table>
                <thead>
                  <tr className="firstTabelRow">
                    <th>Sr. no</th>
                    <th>Employee Name</th>
                    <th>Leave Type</th>
                    <th>Leave From</th>
                    <th>Leave To</th>
                    <th>No.of Leaves</th>
                    <th>Leave Apply Date</th>
                    <th>Reason</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {approveList && approveList.length > 0 ? (
                    approveList
                      .filter((leave) => {
                        if (searchVal.length > 0) {
                          return leave.Full.toLowerCase().includes(
                            searchVal?.toLowerCase()
                          );
                        } else {
                          return leave;
                        }
                      })
                      .map((leave, index) => {
                        return (
                          <tr className="table-row-custom">
                            <td className="table-row-custom-td">
                              {index + 1}{" "}
                            </td>
                            <td> {leave.Full}</td>
                            <td> {leave.Desc}</td>
                            <td>
                              {" "}
                              {leave.LeaveFrom
                                ? getDateddmmyyyy(leave.LeaveFrom)
                                : ""}
                            </td>
                            <td>
                              {" "}
                              {leave.LeaveTo
                                ? getDateddmmyyyy(leave.LeaveTo)
                                : ""}
                            </td>
                            <td> {leave.NoOfLeave}</td>
                            <td>
                              {" "}
                              {leave.LeaveApplicationDate
                                ? getDateddmmyyyy(leave.LeaveApplicationDate)
                                : ""}
                            </td>
                            <td>
                              {" "}
                              <div>{leave.Reason}</div>{" "}
                            </td>
                            <td className="custom-padding-for-btn">
                              {permissions &&
                                permissions["Approve And Reject Leave"] && (
                                  <button
                                    className="btn-green"
                                    onClick={(e) => {
                                      // if (leave.NoOfLeave > 1) {
                                      setIsReject(false);
                                      setSelectedLeaves({ approvals: [] });
                                      setShow(true);
                                      getApproveSubList(
                                        leave.LeaveApplicationId,
                                        "Approve"
                                      );
                                      // }
                                      // else {
                                      //   approveAndRejectAPI(leave.NoOfLeave, {
                                      //     approvals: [
                                      //       {
                                      //         EmpId: Number(userData.EmpId),
                                      //         OrgId: Number(userData.OrgId),
                                      //         Approvedby: Number(userData.EmpId),
                                      //         isApproved: true,
                                      //         SubLeaveId: leave.SubLeavAppId,
                                      //         LeaveId: leave.LeaveApplicationId,
                                      //       },
                                      //     ],
                                      //   });
                                      //    // getApproveSubList(
                                      //   //   leave.LeaveApplicationId,
                                      //   //   "Reject"
                                      //   // );
                                      // }
                                    }}
                                  >
                                    Approve
                                  </button>
                                )}
                              {permissions &&
                                permissions["Approve And Reject Leave"] && (
                                  <button
                                    disabled={buttonLoader ? true : false}
                                    className="btn-red"
                                    onClick={(e) => {
                                      // if (leave.NoOfLeave > 1) {
                                      setIsReject(true);
                                      setSelectedLeaves({ approvals: [] });
                                      setShow(true);
                                      getApproveSubList(
                                        leave.LeaveApplicationId,
                                        "Reject"
                                      );
                                      // }
                                      // else {
                                      //   approveAndRejectAPI(leave.NoOfLeave, {
                                      //     approvals: [
                                      //       {
                                      //         EmpId: Number(userData.EmpId),
                                      //         OrgId: Number(userData.OrgId),
                                      //         Approvedby: Number(userData.EmpId),
                                      //         isApproved: false,
                                      //         SubLeaveId: leave.SubLeavAppId,
                                      //         LeaveId: leave.LeaveApplicationId,
                                      //       },
                                      //     ],
                                      //   });
                                      //   // getApproveSubList(
                                      //   //   leave.LeaveApplicationId,
                                      //   //   "Reject"
                                      //   // );
                                      // }
                                    }}
                                  >
                                    Reject
                                  </button>
                                )}
                            </td>
                          </tr>
                        );
                      })
                  ) : (
                    <tr className="ErrorDataNotFound">
                      <td colSpan={6}>Data Not Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>

        {/* ================================= subList Modal ================================ */}

        <Modal
          size="lg"
          show={show}
          onHide={handleClose}
          aria-labelledby="modal-90w"
          className="add-task-modal-main"
          centered
        >
          <Modal.Header closeButton className="add-task-modal-header ">
            <Modal.Title
              id="example-modal-sizes-title-lg"
              className="add-task-modal-title"
            >
              Leave Details
            </Modal.Title>
          </Modal.Header>
          <div className="lineSeperate"></div>
          <Modal.Body>
            <div className="leaveDetails-table-wrapper">
              <table>
                <thead>
                  <tr className="lineSeperate">
                    <th>Sr. No.</th>
                    <th>Date</th>
                    <th>Reason</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {subListLoader ? (
                    <div
                      class="spinner-border text-info"
                      role="status"
                      style={{ marginLeft: "200%", marginTop: "20px" }}
                    ></div>
                  ) : subapproveList && subapproveList.length > 0 ? (
                    subapproveList.map((item, index) => {
                      return (
                        <tr>
                          <td>{index + 1} </td>
                          <td>{getDateddmmyyyy(item.LeaveDate)} </td>
                          <td>{item.Reason} </td>
                          <td
                            className={
                              item.Status === "A"
                                ? "status-green"
                                : item.Status === "P"
                                ? "status-label-grey"
                                : item.Status === "R"
                                ? "status-red"
                                : "status-label-grey"
                            }
                          >
                            {item?.Status === "P" ? (
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckDefault"
                                onChange={(e) => {
                                  let filteredData =
                                    selectedLeaves.approvals.filter(
                                      (innerItem) => {
                                        return (
                                          innerItem.SubLeaveId !==
                                          item.SubLeaveId
                                        );
                                      }
                                    );
                                  if (e.target.checked) {
                                    setSelectedLeaves({
                                      ...selectedLeaves,
                                      approvals: [
                                        ...selectedLeaves.approvals,
                                        {
                                          EmpId: item.EmpId,
                                          OrgId: item.OrgId,
                                          Approvedby: Number(userData.EmpId),
                                          isApproved: isReject
                                            ? !e.target.checked
                                            : e.target.checked,
                                          SubLeaveId: item.SubLeaveId,
                                          LeaveId: item.LeavApplicationId,
                                        },
                                      ],
                                    });
                                  } else {
                                    setSelectedLeaves({
                                      ...selectedLeaves,
                                      approvals: filteredData,
                                    });
                                  }
                                }}
                              />
                            ) : item.Status === "A" ? (
                              <span>Approve</span>
                            ) : item.Status === "R" ? (
                              <span>Reject</span>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr className="ErrorDataNotFound">
                      <td colSpan={6}>Data Not Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Modal.Body>

          <div className="lineSeperate"></div>
          <div
            className="d-flex justify-content-end align-items-center mainBtnsSub"
            id="leave-detail-btns"
          >
            <button
              type="button"
              className="btn-width cancelBtn mx-3"
              onClick={(e) => {
                setShow(false);
                setSelectedLeaves({ approvals: [] });
                setButtonLoader(false);
              }}
            >
              Cancel
            </button>
            <button
              disabled={buttonLoader ? true : false}
              className="btn-width saveBtn"
              onClick={(e) => {
                approveAndRejectAPI(subapproveList.length);
              }}
            >
              {buttonLoader ? btnLoader : isReject ? "Reject" : "Approve"}
            </button>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default LeaveApprove;
