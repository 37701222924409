import React, { useState, useContext, useEffect, useRef } from "react";
import { AppContext } from "../../../context/AppContext";
import { Link } from "react-router-dom";
import { Form, Modal } from "react-bootstrap";
import deleteicon from "../../../assets/images/DelectAcBlc.svg";
import editicon from "../../../assets/images/EditacBlc.svg";
import { DeleteCallWithErrorResponseWithTokenQuery, PostCallWithErrorResponse, putWithAuthCall, simpleGetCallWithToken } from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
// import { ToastMsg } from "../../../toaster/notify";
import { ToastMsg } from "../../TostMsg";
import Loader from "../../Loader";
import searchicon from '../../../assets/images/ic_search_dark.svg'
import ic_search_dark from "../../../assets/images/ic_search_dark.svg";
import NoData from "../../../sharedComponent/NoData";


const ManageDesig = () => {
  const { sidebar, setSidebar, Dark, setDark, userData, permissions } =
    useContext(AppContext);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false)
  const [departmentList, setDepartMentList] = useState([]);
  const [departmentName, setDepatMentName] = useState("")
  const deleteId = useRef()
  const UpdateDesigetionId = useRef()
  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const [showed, setShowed] = useState(false);
  const handleClosed = () => {
    setShowed(false);
  };
  const handleShowed = (item) => {
    console.log("department Details--->", item)
    setDepatMentName(item.DesignationName)
    UpdateDesigetionId.current = item.Id
    setShowed(true)

  };

  const [showdel, setShowdel] = useState(false);
  const handleClosedel = () => {
    setShowdel(false);
  };
  const handleShowdel = (item) => {
    console.log("department Delete--->", item)
    deleteId.current = item?.Id
    setShowdel(true)
  };

  const getDepartmentList = () => {
    setLoading(true)
    simpleGetCallWithToken(ApiConfig.GET_DESIGNEGETION_LIST)
      .then((res) => {
        if (res.Success === true) {
          setLoading(false)
          setDepartMentList(res?.Data)
        }

        console.log("department List---->", res?.Data)
      })
      .catch((err) => {
        console.log("Error department List---->", err)

      })
  }

  useEffect(() => {
    getDepartmentList()


  }, [])

  useEffect(()=>{
    const filteredDepartmentList = departmentList.filter((item) =>
      item.DesignationName.toLowerCase().includes(searchValue.toLowerCase())
    );
    setSearchResult(filteredDepartmentList)
  },[searchValue,departmentList])

  const onSubmitDepartmentFrom = (e) => {
    e.preventDefault();
    setLoading(true)
    const body = {
      DesignationId:parseInt(UpdateDesigetionId.current),
      DesignationName: departmentName
    }
    PostCallWithErrorResponse(ApiConfig.ADD_DESIGNEGETION, JSON.stringify(body))
      .then((res) => {
        console.log("department Add---->", res)
        if (res.json.Success === true) {
          ToastMsg("success", res.json?.Message)
          setShowed(false)
          setLoading(false)
        } else {
          ToastMsg("error", res.json?.Message)
          setLoading(false)
          setShowed(false)

        }
      })
      .catch((err) => {
        console.log("Error department Add---->", err)
      })

  }

  const onDelete = (e) => {
    e.preventDefault();
    console.log("delete Id--->", deleteId.current)
    const body = {
      id: deleteId.current
    }
    DeleteCallWithErrorResponseWithTokenQuery(ApiConfig.DELETE_DESIGNEGETION, parseInt(body))
      .then((res) => {
        console.log("department dELETE---->", res)
        if (res.json.Success === true) {
          ToastMsg("success", res.json?.Message)
          setShow(false)
          setLoading(false)
        } else {
          ToastMsg("error", res.json?.Message)
          setLoading(false)

        }
      })
      .catch((err) => {
        console.log("Error department Add---->", err)
      })

  }

  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div className="role-content">
        <div className="sub-header-role ">
          <h6 className="mt-2">Organization Info</h6>
        </div>
      </div>
      <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary">
        <div className="role-table-header accoridance">
          <div className="search-input-wrapper allAcordiance">
            {
              <Link to="/OrgDetails">
                <button className="all-anc-btn-sub-header">
                  Organization Details
                </button>
              </Link>
            }
          </div>
          <div className="entries-wrapper myAccordiance">
            {
              <Link to="/ManageDept">
                <button className="my-anc-btn-sub-header">
                  Manage Department
                </button>
              </Link>
            }
          </div>
          <div className="entries-wrapper myAccordiance">
            {
              <Link to="/ManageDesig">
                <button className="my-anc-btn-sub-header active">
                  Manage Designation
                </button>
              </Link>
            }
          </div>
          <div className="entries-wrapper myAccordiance">
            {
              <Link to="/ManageLoc">
                <button className="my-anc-btn-sub-header">
                  Manage Locations
                </button>
              </Link>
            }
          </div>
        </div>
      </div>
      <div>
      {/* <div className="role-table-header"> */}
      <div className="d-flex  justify-content-between ">
      
              <div className="search-input-wrapper">
                <input
                  type="text"
                  placeholder="Department Name"
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                  }}
                />
                {Dark === "lightMode" ? (
                  <img src={searchicon} alt="" />
                ) : (
                  <img src={ic_search_dark} alt="" />
                )}
              </div>
              <div className="d-flex justify-content-end my-3 ">
          <button className="allocateionBtn mt-2" onClick={handleShowed}>
            Add New Record
          </button>
        </div>
        </div>
           
       
        <div
          className="table-wrapper custom-table-width"
          id="scrol-tblNtable-wrapper-af"
        >

          {
            loading ? (
              <>
                <Loader />
              </>
            ) : searchResult && searchResult.length > 0 ? (
              <table>
                <thead>
                  <tr className="firstTabelRow">
                    <th>Sr. no.</th>
                    <th>Department Name</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {searchResult.map((item, index) => (
                    <tr className="table-row-custom" key={index}>
                      <td>{index + 1}</td>
                      <td>{item?.DesignationName}</td>
                      <td>
                        <img onClick={() => handleShowed(item)} src={editicon} alt="Edit Icon" />
                        <img onClick={() => handleShowdel(item)} src={deleteicon} alt="Delete Icon" />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <>
              <NoData/>
              </>
            )
          }


        </div>
        <Modal
          show={showed}
          onHide={handleClosed}
          //dialogClassName="custom-modal"
          size="lg"
        >
          <Modal.Header closeButton className="custom-close-btn">
            <Modal.Title className="custom-modal-title">
              Add New Record
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="company" className="mb-3">
                <Form.Label>Designation Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Designation Name"
                  required
                  value={departmentName}
                  onChange={(e) => setDepatMentName(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Designation Name!
                </Form.Control.Feedback>
              </Form.Group>
              <hr />
              <div className="d-flex justify-content-end">
                <button className="allocateionBtnwhite mx-2" onClick={handleClosed}>
                  cancel
                </button>
                <button className="btn active-tab" onClick={onSubmitDepartmentFrom}>save</button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>

        {/* <Modal
          show={showed}
          onHide={handleClosed}
          //dialogClassName="custom-modal"
          size="lg"
        >
          <Modal.Header closeButton className="custom-close-btn">
            <Modal.Title className="custom-modal-title">
              Add New Record
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="company" className="mb-3">
                <Form.Label>Designation Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Designation Name"
                  required
                // value={details?.company}
                //  onChange={(e) => handleChage("company", e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Designation Name!
                </Form.Control.Feedback>
              </Form.Group>
              <hr />
              <div className="d-flex justify-content-end">
                <button className="mx-2 allocateionBtnwhite" onClick={handleClosed}>
                  cancel
                </button>
                <button className="btn active-tab">save</button>
              </div>
            </Form>
          </Modal.Body>
        </Modal> */}

        <Modal
          show={showdel}
          onHide={handleClosedel}
          //dialogClassName="custom-modal"
          size="lg"
        >
          <Modal.Body>
            <h5 className="text-center">Be sure to delete it !</h5>
            <Form>
              <div className="d-flex justify-content-center">
                <button className="allocateionBtnwhite mx-2" onClick={handleClosedel}>
                  cancel
                </button>
                <button className="btn active-tab" onClick={onDelete}>confirm</button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};
export default ManageDesig;
