import ActiveMan from "../assets/images/userActive.svg";
import CRMDashboard_active from "../assets/images/CRMDashboard_active.svg";
import CRMDashboard_inactive from "../assets/images/sidebar/CRMDahboard.svg";
import CRMOfficeCaller_active from "../assets/images/CRMOfficecaller_active..svg";
import CRMphone_iactive from "../assets/images/sidebar/CRMPhonebook.svg";
import ClientMan from "../assets/images/sidebar/clientMan.svg";
import ClientManActive from "../assets/images/sidebar/clientManActive.svg";
import Compliance from "../assets/images/sidebar/icons/ComplianceIcon.svg";
import ComplianceActive from "../assets/images/sidebar/icons/ComplianceActiveIcon.svg";
import EmployeeManagementActiveIcon from "../assets/images/sidebar/icons/EmployeeManagementActiveIcon.svg";
import EmployeeManagementIcon from "../assets/images/sidebar/icons/EmployeeManagementIcon.svg";
import GeneralSettingActiveIcon from "../assets/images/sidebar/icons/GeneralSettingActiveIcon.svg";
import GeneralSettingIcon from "../assets/images/sidebar/icons/GeneralSettingIcon.svg";
import Logo from "../assets/images/wifi-attendance.svg";
import Organ_Dark from "../assets/images/sidebar/icons/OrganDark.svg";
import Organ_active from "../assets/images/sidebar/icons/Organ.svg";
import ProjectMan_Dark from "../assets/images/sidebar/icons/ProjectManDark.svg";
import ProjectMan_active from "../assets/images/sidebar/icons/ProjectMan.svg";
import React, { useContext, useEffect, useState } from "react";
import TeamsManagementActiveIcon from "../assets/images/sidebar/icons/TeamsManagementActiveIcon.svg";
import TeamsManagementIcon from "../assets/images/sidebar/icons/TeamsManagementIcon.svg";
import attendance_icon from "../assets/images/sidebar/icons/attendance.svg";
import attendance_icon_active from "../assets/images/sidebar/icons/attendance_active.svg";
import close from "../assets/images/close.svg";
import comm_icon from "../assets/images/sidebar/icons/comm.svg";
import comm_icon_Dark from "../assets/images/sidebar/icons/communcationDark.svg";
import comm_icon_active from "../assets/images/sidebar/icons/communcation.svg";
import dashboard_icon from "../assets/images/sidebar/icons/dashboard.svg";
import dashboard_icon_active from "../assets/images/sidebar/icons/dashboard_active.svg";
import down_arrow from "../assets/images/down_arrow.svg";
import ic_blueUP_arrow from "../assets/images/sidebar/ic_blueUP_arrow.svg";
import icon_logo from "../assets/images/icon_logo.png";
import leave_icon from "../assets/images/sidebar/icons/leave.svg";
import leave_icon_active from "../assets/images/sidebar/icons/leave_active.svg";
import noti_icon from "../assets/images/sidebar/icons/noti.svg";
import noti_icon_active from "../assets/images/sidebar/icons/noti_active.svg";
import projCost from "../assets/images/sidebar/icons/ProjectCostingIcon.svg";
import projCostActive from "../assets/images/sidebar/icons/ProjectCostingActiveIcon.svg";
import taskmanag_icon from "../assets/images/sidebar/icons/taskmanag.svg";
import taskmanag_icon_active from "../assets/images/sidebar/icons/taskmanag_active.svg";
import teamPre from "../assets/images/TeamPre.svg";
import teamPreActive from "../assets/images/TeamPreActive.svg";
import timesheet_icon from "../assets/images/sidebar/icons/timesheet.svg";
import timesheet_icon_active from "../assets/images/sidebar/icons/timesheet_active.svg";
import wallet from "../assets/images/wallet.svg";
import walletActive from "../assets/images/walletActive.svg";
import { Accordion, Modal } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../context/AppContext";

const Sidebar = ({ setLoggedIn }) => {
  const navigate = useNavigate();
  const currentRoute = useLocation().pathname;
  const [activeSidebar, setActiveSidebar] = useState("");
  const {
    setUserData,
    userData,
    setSocket,
    socket,
    openSessionLogoutModal,
    setOpenSessionLogoutModal,
    setActiveChat,
    setCurrentSChannel,
  } = useContext(AppContext);
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });
  let permissions = JSON.parse(localStorage.getItem("Permissions"))
    ? JSON.parse(localStorage.getItem("Permissions"))
    : {};
  console.log("Old permissions", permissions);

  useEffect(() => {
    isMobile ? setSidebar(false) : setSidebar(true);
    handleOpenMenu();
    return () => {};
  }, [isMobile]);
  const [Purchasemenue, setPurchasemenue] = useState();

  const handleOpenMenu = () => {
    if (currentRoute === "/" || currentRoute === "/") {
      setDashboard(true);
    } else if (currentRoute === "/" || currentRoute === "/") {
      setPurchasemenue(true);
    }
  };

  const [Dashboard, setDashboard] = useState(false);
  const handleDashboard = () => {
    setDashboard(!Dashboard);
  };
  const [clickMenu, setClickMenu] = useState("");
  const [TaskManagement, setTaskManagement] = useState(false);
  const handleTaskManagement = () => setTaskManagement(!TaskManagement);

  const [Attendance, setAttendance] = useState(false);
  const handleAttendance = () => setAttendance(!Attendance);

  const [Leave, setLeave] = useState(false);
  const handleLeave = () => setLeave(!Leave);

  const [approveLeave, setApproveLeave] = useState(false);
  const handleApproveLeave = () => setApproveLeave(!approveLeave);

  const [Timesheet, setTimesheet] = useState(false);
  const handleTimesheet = () => setTimesheet(!Timesheet);

  const [userManagement, setUserManagement] = useState(false);
  const handleUserMan = () => setUserManagement(!userManagement);

  const [Notifications, setNotifications] = useState(false);
  const handleNotifications = () => setNotifications(!Notifications);

  const [walletCheck, setWalletCheck] = useState(false);
  const handleWallet = () => setWalletCheck(!Notifications);

  const [Communication, setCommunication] = useState(false);
  const handleCommunication = () => setCommunication(!Communication);

  const [ProjectMan, setProjectMan] = useState(false);
  const handleProjectMan = () => setProjectMan(!ProjectMan);

  ///////////////////////////////////

  const [CRMPhoneBook, setCRMphoneook] = useState(false);
  const handleRMPhoneBook = () => setCRMphoneook(!CRMPhoneBook);
  const [CRMOfficeCaller, setCRMOfficeCaller] = useState(false);
  const handleCRMOfficeCaller = () => setCRMOfficeCaller(!CRMOfficeCaller);
  const [CRMDashboard, setCRMDashboard] = useState(false);
  const handleCRMDashboard = () => setCRMDashboard(!CRMDashboard);

  const [LeadOpen, setLeadOpen] = useState(false);
  const [QueryOpen, setQueryOpen] = useState(false);
  const [SettingsOpen, setSettingsOpen] = useState(false);
  const [CandidateOpen, setCandidateOpen] = useState(false);
  const [ContactCustomGroup, setContactCustomGroup] = useState(false);
  const [unreadProjectCount, setUnreadProjectCount] = useState(0);
  const [unreadTeamCount, setUnreadTeamCount] = useState(0);

  const [chatCount, setChatCount] = useState(0);

  const [DomainOpen, setDomainOpen] = useState(false);

  const {
    sidebar,
    setSidebar,
    Dark,
    Newpermissions,
    unreadCount,
    setProjects,
    setCallPage,
    setCallLoadMore,
    setTeams,
    recentChats,
    projects,
    teams,
  } = useContext(AppContext);
  const handleSidebar = () => {
    setSidebar(!sidebar);
  };
  console.log("====================================");
  console.log("Newpermissions amerrr", Newpermissions);
  console.log("====================================");
  const [isActive, setIsActive] = useState(false);
  const handleClick = (Event) => {
    // 👇️ toggle isActive state on click
    setIsActive((current) => !current);
  };

  const [logoutModalShow, setLogoutModalShow] = useState(false);

  const logout = (e) => {
    localStorage.clear();
    socket && socket.disconnect();
    setSocket(null);
    setUserData({});
    setLoggedIn(false);
    setLogoutModalShow(false);
    setOpenSessionLogoutModal(false);
  };
  const getProjects = async () => {
    if (socket) {
      socket.emit("get-projects-android", {});
      socket &&
        socket.on("get-projects-android", (data) => {
          setProjects(
            data.map((item) => {
              return { ...item, colapse: false };
            })
          );
        });

      return () => {
        if (socket) {
          socket.off("get-projects-android");
        }
      };
    }
  };

  const getTeams = async () => {
    if (socket) {
      socket.emit("get-teams-android", {});
      socket &&
        socket.on("get-teams-android", (data) => {
          console.log(data);
          setTeams(
            data.map((item) => {
              return { ...item, colapse: false };
            })
          );
        });
      return () => {
        if (socket) {
          socket.off("get-teams-android");
        }
      };
    }
  };

  const getCallHistory = async () => {
    if (socket) {
      setCallPage(0);
      setCallLoadMore(true);
      socket.emit("call-history", { page: 0 });
    }
  };
  const getPrivateChat = async () => {
    if (socket) {
      socket.emit("get-private-chat-list", {});
    }
  };

  useEffect(() => {
    if (recentChats?.length > 0) {
      let count = recentChats?.filter((i) => {
        return i?.unread_count > 0;
      });

      setChatCount(count.length);

      let countPrjt = 0;

      console.log(projects);

      projects &&
        projects?.forEach((item) => {
          let indx = item?.subchannel.findIndex((i) => {
            return i.unread_count > 0;
          });
          if (indx != -1) {
            countPrjt++;
          }
        });

      setUnreadProjectCount(countPrjt);

      let countTeams = 0;

      teams &&
        teams?.forEach((item) => {
          let indx = item?.subchannel?.findIndex((i) => {
            return i.unread_count > 0;
          });
          if (indx != -1) {
            countTeams++;
          }
        });

      setUnreadTeamCount(countTeams);
    }
  }, [recentChats, projects, teams]);

  return (
    <main className="cx-sidebar">
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={logoutModalShow}
        className="create-workflow-main modal-md"
        onHide={() => setLogoutModalShow(false)}
      >
        <Modal.Header>
          <Modal.Title>
            <label htmlFor="" className="create-role-text">
              User Logout
            </label>
          </Modal.Title>
          <img
            src={close}
            alt=""
            onClick={() => setLogoutModalShow(false)}
            className="cr-class"
          />
        </Modal.Header>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Body className="text-center">
          Are you sure you want to Logout ?
        </Modal.Body>
        <div
          style={{ padding: "15px", display: "flex", justifyContent: "center" }}
        >
          <button
            className="modal-cancel-btn sm-btn-delete"
            onClick={() => setLogoutModalShow(false)}
          >
            Cancel
          </button>
          <button className="modal-create-btn sm-btn-delete" onClick={logout}>
            Yes
          </button>
        </div>
      </Modal>

      {/* Session Logout Modal */}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={openSessionLogoutModal}
        className="create-workflow-main modal-md"
      >
        <Modal.Header>
          <Modal.Title>
            <label htmlFor="" className="create-role-text">
              Session expired !
            </label>
          </Modal.Title>
          {/* <img
            src={close}
            alt=''
            onClick={() => setLogoutModalShow(false)}
            className='cr-class'
          /> */}
        </Modal.Header>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Body className="text-center">
          Please log in again to continue using the app.
        </Modal.Body>
        <div
          style={{ padding: "15px", display: "flex", justifyContent: "end" }}
        >
          <button className="modal-create-btn sm-btn-delete" onClick={logout}>
            Log in
          </button>
        </div>
      </Modal>
      {/* Session Logout Modal */}

      <div
        className={
          sidebar ? "cx-sidebar-wrapper " : "cx-sidebar-wrapper sidebarActive"
        }
      >
        <aside>
          {sidebar ? (
            <div className="top-logo-big">
              <div className="left">
                <Link to="/Dashboard">
                  <img src={Logo} alt="Logo" />
                </Link>
              </div>
              {/* <div className="right">
                <Link to="#">
                  <img src={down_arrow} alt="" />
                </Link>
              </div> */}
            </div>
          ) : (
            <div className="small-logo">
              <Link to="/dashboard">
                <img src={icon_logo} alt="" />
              </Link>
            </div>
          )}

          <div className="left-arrow">
            <div
              className={sidebar ? "text-end " : "doubleArrowActive"}
              onClick={handleSidebar}
            >
              {currentRoute === "/" ||
              currentRoute === "/" ||
              currentRoute === "/" ||
              currentRoute === "/" ||
              currentRoute === "/" ||
              currentRoute === "/" ? (
                <>
                  <svg
                    width="10"
                    height="17"
                    viewBox="0 0 10 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.302 16.0846L0.281168 9.08463C0.197835 9.0013 0.138946 8.91102 0.104502 8.8138C0.0695017 8.71658 0.052002 8.61241 0.052002 8.5013C0.052002 8.39019 0.0695017 8.28602 0.104502 8.1888C0.138946 8.09158 0.197835 8.0013 0.281168 7.91797L7.302 0.897135C7.49645 0.702691 7.7395 0.605469 8.03117 0.605469C8.32283 0.605469 8.57283 0.709635 8.78117 0.917969C8.9895 1.1263 9.09367 1.36936 9.09367 1.64714C9.09367 1.92491 8.9895 2.16797 8.78117 2.3763L2.65617 8.5013L8.78117 14.6263C8.97561 14.8207 9.07283 15.0602 9.07283 15.3446C9.07283 15.6296 8.96867 15.8763 8.76033 16.0846C8.552 16.293 8.30895 16.3971 8.03117 16.3971C7.75339 16.3971 7.51033 16.293 7.302 16.0846Z"
                      fill="#3DA298"
                    />
                  </svg>
                  <svg
                    width="10"
                    height="17"
                    viewBox="0 0 10 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.302 16.0846L0.281168 9.08463C0.197835 9.0013 0.138946 8.91102 0.104502 8.8138C0.0695017 8.71658 0.052002 8.61241 0.052002 8.5013C0.052002 8.39019 0.0695017 8.28602 0.104502 8.1888C0.138946 8.09158 0.197835 8.0013 0.281168 7.91797L7.302 0.897135C7.49645 0.702691 7.7395 0.605469 8.03117 0.605469C8.32283 0.605469 8.57283 0.709635 8.78117 0.917969C8.9895 1.1263 9.09367 1.36936 9.09367 1.64714C9.09367 1.92491 8.9895 2.16797 8.78117 2.3763L2.65617 8.5013L8.78117 14.6263C8.97561 14.8207 9.07283 15.0602 9.07283 15.3446C9.07283 15.6296 8.96867 15.8763 8.76033 16.0846C8.552 16.293 8.30895 16.3971 8.03117 16.3971C7.75339 16.3971 7.51033 16.293 7.302 16.0846Z"
                      fill="#3DA298"
                    />
                  </svg>
                </>
              ) : (
                <>
                  <svg
                    width="10"
                    height="17"
                    viewBox="0 0 10 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.302 16.0846L0.281168 9.08463C0.197835 9.0013 0.138946 8.91102 0.104502 8.8138C0.0695017 8.71658 0.052002 8.61241 0.052002 8.5013C0.052002 8.39019 0.0695017 8.28602 0.104502 8.1888C0.138946 8.09158 0.197835 8.0013 0.281168 7.91797L7.302 0.897135C7.49645 0.702691 7.7395 0.605469 8.03117 0.605469C8.32283 0.605469 8.57283 0.709635 8.78117 0.917969C8.9895 1.1263 9.09367 1.36936 9.09367 1.64714C9.09367 1.92491 8.9895 2.16797 8.78117 2.3763L2.65617 8.5013L8.78117 14.6263C8.97561 14.8207 9.07283 15.0602 9.07283 15.3446C9.07283 15.6296 8.96867 15.8763 8.76033 16.0846C8.552 16.293 8.30895 16.3971 8.03117 16.3971C7.75339 16.3971 7.51033 16.293 7.302 16.0846Z"
                      fill="#53B7E8"
                    />
                  </svg>
                  <svg
                    width="10"
                    height="17"
                    viewBox="0 0 10 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.302 16.0846L0.281168 9.08463C0.197835 9.0013 0.138946 8.91102 0.104502 8.8138C0.0695017 8.71658 0.052002 8.61241 0.052002 8.5013C0.052002 8.39019 0.0695017 8.28602 0.104502 8.1888C0.138946 8.09158 0.197835 8.0013 0.281168 7.91797L7.302 0.897135C7.49645 0.702691 7.7395 0.605469 8.03117 0.605469C8.32283 0.605469 8.57283 0.709635 8.78117 0.917969C8.9895 1.1263 9.09367 1.36936 9.09367 1.64714C9.09367 1.92491 8.9895 2.16797 8.78117 2.3763L2.65617 8.5013L8.78117 14.6263C8.97561 14.8207 9.07283 15.0602 9.07283 15.3446C9.07283 15.6296 8.96867 15.8763 8.76033 16.0846C8.552 16.293 8.30895 16.3971 8.03117 16.3971C7.75339 16.3971 7.51033 16.293 7.302 16.0846Z"
                      fill="#53B7E8"
                    />
                  </svg>
                </>
              )}
            </div>
          </div>
          <div className="right-arrow"></div>
          <div className="menus-main">
            <Accordion defaultActiveKey="0">
              {permissions["Employee Wallet"] != true ? (
                <>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <div className="menus-items">
                        {/* ================> START HOME <============== */}
                        <Link
                          to="/Dashboard"
                          className="icon-menu-name"
                          onClick={() => {
                            setWalletCheck(false);
                            handleDashboard();
                            setAttendance(false);
                            setTaskManagement(false);
                            setApproveLeave(false);
                            setLeave(false);
                            setTimesheet(false);
                            setNotifications(false);
                            setCommunication(false);
                          }}
                        >
                          <div className="icon-left">
                            {currentRoute === "/Dashboard" ? (
                              <img src={dashboard_icon_active} alt="" />
                            ) : (
                              <img src={dashboard_icon} alt="" />
                            )}
                          </div>
                          <div
                            className={
                              currentRoute === "/Dashboard"
                                ? "menu-name activeColorBlue"
                                : "menu-name"
                            }
                          >
                            Dashboard
                          </div>
                        </Link>
                      </div>
                    </Accordion.Header>
                  </Accordion.Item>
                  {/* ================> END HOME <============== */}

                  {/* =================> START Attendance <============= */}
                  {permissions &&
                  (permissions["View My Attendance"] ||
                    permissions["View Subordinates Attendance"] ||
                    permissions["View Employee Attendance Report"] ||
                    permissions["View Attendance History"] ||
                    permissions["View Apply Leave"] ||
                    permissions["View Approve Leave"] ||
                    permissions["View Branch Attendance Report"] ||
                    permissions["View User Face Detection"] ||
                    permissions["View User Tracking"]) ? (
                    <div className="with-subMenu">
                      <Accordion.Item eventKey="1">
                        <Accordion.Header className="menus-items ">
                          <Link
                            to="#"
                            // className="icon-menu-name-arrow"
                            className={
                              isActive
                                ? "icon-menu-name-arrow activeColorBlue"
                                : "icon-menu-name-arrow"
                            }
                            onClick={() => {
                              handleAttendance();
                              setTaskManagement(false);
                              setLeave(false);
                              setApproveLeave(false);
                              setWalletCheck(false);
                              setDashboard(false);
                              setTimesheet(false);
                              setNotifications(false);
                              setCommunication(false);
                              setProjectMan(false);
                            }}
                          >
                            <div className="menu-left">
                              <div className="icon-left">
                                <Link to="/attendance">
                                  {currentRoute === "/attendance" ||
                                  currentRoute === "/EmployeeAttendence" ||
                                  currentRoute ===
                                    "/EmployeeAttendenceReport" ||
                                  currentRoute === "/DevicePunchHistory" ||
                                  currentRoute === "/LeaveApprove" ||
                                  currentRoute === "/Leave" ||
                                  currentRoute ===
                                    "/AllEmployeeAttendanceReport" ||
                                  currentRoute === "/FaceDetection" ||
                                  currentRoute === "/UserTracking" ? (
                                    <img src={attendance_icon_active} alt="" />
                                  ) : (
                                    <img src={attendance_icon} alt="" />
                                  )}
                                </Link>
                              </div>
                              <div
                                onClick={() => {
                                  handleClick();
                                }}
                                className={
                                  currentRoute === "/attendance" ||
                                  currentRoute ===
                                    "/View User Face Detection" ||
                                  currentRoute === "/EmployeeAttendence" ||
                                  currentRoute ===
                                    "/EmployeeAttendenceReport" ||
                                  currentRoute === "/DevicePunchHistory" ||
                                  currentRoute === "/LeaveApprove" ||
                                  currentRoute === "/Leave" ||
                                  currentRoute === "/FaceDetection" ||
                                  currentRoute ===
                                    "/AllEmployeeAttendanceReport" ||
                                  currentRoute === "/UserTracking"
                                    ? "menu-name activeColorBlue"
                                    : "menu-name "
                                }
                              >
                                Attendance
                              </div>
                            </div>

                            <div className="arrow">
                              {Attendance ? (
                                <img src={down_arrow} alt="" />
                              ) : (
                                <img src={ic_blueUP_arrow} alt="" />
                              )}
                            </div>
                          </Link>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className={sidebar ? "sub-menus" : "none"}>
                            <Link
                              to="/attendance"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              {permissions &&
                                permissions["View My Attendance"] && (
                                  <div
                                    className={
                                      currentRoute === "/attendance"
                                        ? "menu-name activeColorBlue"
                                        : "menu-name"
                                    }
                                  >
                                    My Attendance
                                  </div>
                                )}
                            </Link>

                            <Link
                              to="/EmployeeAttendence"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              {permissions &&
                                permissions["View Subordinates Attendance"] && (
                                  <div
                                    className={
                                      currentRoute === "/EmployeeAttendence"
                                        ? "menu-name activeColorBlue"
                                        : "menu-name"
                                    }
                                  >
                                    Subordinates <br /> Attendance
                                  </div>
                                )}
                            </Link>

                            <Link
                              to="/EmployeeAttendenceReport"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              {permissions &&
                                permissions[
                                  "View Employee Attendance Report"
                                ] && (
                                  <div
                                    className={
                                      currentRoute ===
                                      "/EmployeeAttendenceReport"
                                        ? "menu-name activeColorBlue"
                                        : "menu-name"
                                    }
                                  >
                                    Employee Attendance Report
                                  </div>
                                )}
                            </Link>
                            <Link
                              to="/DevicePunchHistory"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              {permissions &&
                                permissions["View Attendance History"] && (
                                  <div
                                    className={
                                      currentRoute === "/DevicePunchHistory"
                                        ? "menu-name activeColorBlue"
                                        : "menu-name"
                                    }
                                  >
                                    Attendance History
                                  </div>
                                )}
                            </Link>
                            <Link
                              to="/Leave"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              {permissions &&
                                permissions["View Apply Leave"] && (
                                  <div
                                    className={
                                      currentRoute === "/Leave"
                                        ? "menu-name activeColorBlue"
                                        : "menu-name"
                                    }
                                  >
                                    Apply Leave
                                  </div>
                                )}
                            </Link>
                            <Link
                              to="/LeaveApprove"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              {permissions &&
                                permissions["View Approve Leave"] && (
                                  <div
                                    className={
                                      currentRoute === "/LeaveApprove"
                                        ? "menu-name activeColorBlue"
                                        : "menu-name"
                                    }
                                  >
                                    Approve <br /> Leave
                                  </div>
                                )}
                            </Link>
                            <Link
                              to="/FaceDetection"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              {permissions &&
                                permissions["View User Face Detection"] && (
                                  <div
                                    className={
                                      currentRoute === "/FaceDetection"
                                        ? "menu-name activeColorBlue"
                                        : "menu-name"
                                    }
                                  >
                                    Face Detection
                                  </div>
                                )}
                            </Link>
                            <Link
                              to="/UserTracking"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              {permissions &&
                                permissions["View User Tracking"] && (
                                  <div
                                    className={
                                      currentRoute === "/UserTracking"
                                        ? "menu-name activeColorBlue"
                                        : "menu-name"
                                    }
                                  >
                                    User Tracking
                                  </div>
                                )}
                            </Link>
                            {/* <Link
                              to="/AllEmployeeAttendanceReport"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              {permissions && permissions["All Employees Attendance"] && (
                                <div
                                  className={
                                    currentRoute === "/AllEmployeeAttendanceReport"
                                      ? "menu-name activeColorBlue"
                                      : "menu-name"
                                  }
                                >
                                  Employee Attendance Report
                                </div>
                              )}
                            </Link> */}
                            <Link
                              to={"/AllEmployeeAttendanceReport"}
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                              className="sub-menue-items"
                            >
                              {permissions &&
                                permissions[
                                  "View Branch Attendance Report"
                                ] && (
                                  <div
                                    className={
                                      currentRoute ===
                                      "/AllEmployeeAttendanceReport"
                                        ? "menu-name activeColorBlue"
                                        : "menu-name"
                                    }
                                  >
                                    Branch Attendance Report
                                  </div>
                                )}
                            </Link>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </div>
                  ) : null}

                  {/* =================> END Attendance <============= */}

                  {/* =================> START Communication <============= */}

                  {(permissions && permissions["View Chat"]) ||
                  (permissions && permissions["View Communication Projects"]) ||
                  (permissions && permissions["View Teams"]) ||
                  (permissions && permissions["View Calls"]) ||
                  (permissions && permissions["View Ongoing Calls"]) ||
                  (permissions && permissions["View Meetings"]) ? (
                    <div className="with-subMenu">
                      <Accordion.Item eventKey="2">
                        <Accordion.Header className="menus-items ">
                          <Link
                            to="#"
                            className="icon-menu-name-arrow"
                            onClick={() => {
                              handleCommunication();
                              setAttendance(false);
                              setTimesheet(false);
                              setWalletCheck(false);
                              setTaskManagement(false);
                              setNotifications(false);
                              setProjectMan(false);
                            }}
                          >
                            <div className="menu-left">
                              <div className="icon-left">
                                <Link to="/chat">
                                  {currentRoute === "/chat" ||
                                  currentRoute === "/projects" ||
                                  currentRoute === "/Call" ||
                                  currentRoute === "/teams" ||
                                  currentRoute === "/ongoing-calls" ? (
                                    <>
                                      {unreadCount > 0 && (
                                        <span class="badge">{unreadCount}</span>
                                      )}
                                      <img src={comm_icon_Dark} alt="" />
                                    </>
                                  ) : (
                                    <>
                                      {unreadCount > 0 && (
                                        <span class="badge">{unreadCount}</span>
                                      )}
                                      <img src={comm_icon_active} alt="" />
                                    </>
                                  )}
                                </Link>
                              </div>
                              <div
                                className={
                                  currentRoute === "/chat" ||
                                  currentRoute === "/projects" ||
                                  currentRoute === "/Call" ||
                                  currentRoute === "/teams" ||
                                  currentRoute === "/ongoing-calls"
                                    ? "menu-name activeColorBlue"
                                    : "menu-name"
                                }
                              >
                                Communication
                              </div>
                            </div>

                            <div className="arrow">
                              {Communication ? (
                                <img src={down_arrow} alt="" />
                              ) : (
                                <img src={ic_blueUP_arrow} alt="" />
                              )}
                            </div>
                          </Link>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className={sidebar ? "sub-menus" : "none"}>
                            {permissions["View Chat"] && (
                              <Link
                                to="/chat"
                                className="sub-menue-items d-flex "
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                  getPrivateChat();
                                }}
                              >
                                {/* {unreadCount > 0 && <span class="badge">{unreadCount}</span>} */}
                                <div
                                  className={
                                    currentRoute === "/chat"
                                      ? "menu-name activeColorBlue"
                                      : "menu-name"
                                  }
                                  onClick={() => {
                                    setActiveChat({});
                                    setCurrentSChannel(null);
                                  }}
                                >
                                  Chat
                                  {chatCount > 0 && (
                                    <span
                                      style={{
                                        position: "relative",
                                        marginLeft: "-2px",
                                      }}
                                      className=" badge"
                                    >
                                      {chatCount}
                                    </span>
                                  )}
                                </div>
                              </Link>
                            )}
                            {permissions["View Communication Projects"] && (
                              <Link
                                to="/projects"
                                className="sub-menue-items d-flex"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                  getProjects();
                                }}
                              >
                                <div
                                  className={
                                    currentRoute === "/projects"
                                      ? "menu-name activeColorBlue"
                                      : "menu-name"
                                  }
                                  onClick={() => {
                                    setActiveChat({});
                                    setCurrentSChannel(null);
                                  }}
                                >
                                  Projects
                                </div>
                                {unreadProjectCount > 0 && (
                                  <span
                                    style={{
                                      position: "relative",
                                      marginLeft: "-2px",
                                    }}
                                    className=" badge"
                                  >
                                    {unreadProjectCount}
                                  </span>
                                )}
                              </Link>
                            )}
                            {permissions["View Teams"] && (
                              <Link
                                to="/teams"
                                className="sub-menue-items d-flex"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                  getTeams();
                                }}
                              >
                                <div
                                  className={
                                    currentRoute === "/teams"
                                      ? "menu-name activeColorBlue"
                                      : "menu-name"
                                  }
                                  onClick={() => {
                                    setActiveChat({});
                                    setCurrentSChannel(null);
                                  }}
                                >
                                  Teams
                                </div>
                                {unreadTeamCount > 0 && (
                                  <span
                                    style={{
                                      position: "relative",
                                      marginLeft: "-2px",
                                    }}
                                    className=" badge"
                                  >
                                    {unreadTeamCount}
                                  </span>
                                )}
                              </Link>
                            )}
                            {permissions["View Calls"] && (
                              <Link
                                to="/Call"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                  getCallHistory();
                                }}
                              >
                                <div
                                  className={
                                    currentRoute === "/Call"
                                      ? "menu-name activeColorBlue"
                                      : "menu-name"
                                  }
                                >
                                  Calls
                                </div>
                              </Link>
                            )}
                            {permissions["View Ongoing Calls"] && (
                              <Link
                                to="/ongoing-calls"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                  getCallHistory();
                                }}
                              >
                                <div
                                  className={
                                    currentRoute === "/ongoing-calls"
                                      ? "menu-name activeColorBlue"
                                      : "menu-name"
                                  }
                                >
                                  Ongoing Calls
                                </div>
                              </Link>
                            )}
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </div>
                  ) : null}

                  {/* ================> END Communication <============== */}

                  {/* =================>  CRM Dashboard <============= */}
                  {permissions && permissions["notshowfrosometime"] && (
                    <>
                      <div className="with-subMenu">
                        <Accordion.Item eventKey="16">
                          <Accordion.Header>
                            <div className="menus-items">
                              {/* ================> START HOME <============== */}
                              <Link
                                to="/CRMDashboard"
                                className="icon-menu-name"
                              >
                                <div className="icon-left">
                                  {currentRoute === "/CRMDashboard" ? (
                                    <>
                                      {/* {unreadCount > 0 && (
                                      <span class="badge">{unreadCount}</span>
                                    )} */}
                                      <img src={CRMDashboard_active} alt="" />
                                    </>
                                  ) : (
                                    <>
                                      <img src={CRMDashboard_inactive} alt="" />
                                    </>
                                  )}
                                </div>

                                <div
                                  className={
                                    currentRoute === "/CRMDashboard"
                                      ? "menu-name activeColorBlue"
                                      : "menu-name"
                                  }
                                >
                                  CRM Dashboard
                                </div>
                              </Link>
                            </div>
                          </Accordion.Header>
                        </Accordion.Item>
                      </div>

                      {/* ================> END CRM Phonebook <============== */}

                      {/* =================> START CRM Phonebook <============= */}

                      <div className="with-subMenu">
                        <Accordion.Item eventKey="3">
                          <Accordion.Header className="menus-items">
                            <Link
                              to="#"
                              className="icon-menu-name-arrow"
                              onClick={() => {
                                handleRMPhoneBook();
                                // setAttendance(false);
                                // setTimesheet(false);
                                // setWalletCheck(false);
                                // setTaskManagement(false);
                                // setNotifications(false);
                                // setProjectMan(false);
                              }}
                            >
                              <div className="menu-left">
                                <div className="icon-left">
                                  <Link to="/chat">
                                    {currentRoute === "/AgentList" ||
                                    currentRoute === "/CRMCalls" ||
                                    currentRoute === "/Users" ||
                                    currentRoute === "/WhatsappSetting" ||
                                    currentRoute === "/NotCallClassification" ||
                                    currentRoute === "/ZeroCallList" ||
                                    currentRoute === "/FrequentCaller" ||
                                    currentRoute === "/ServiceRequest" ||
                                    currentRoute === "/AgentCallSummary" ||
                                    currentRoute === "/AssignCallUsers" ||
                                    currentRoute ===
                                      "/SalesInternationalcall" ||
                                    currentRoute === "/UploadCandidateOld" ||
                                    currentRoute === "/Supplier" ||
                                    currentRoute === "/Consultant" ||
                                    currentRoute === "/WhatsappPromotion" ? (
                                      <>
                                        {/* {unreadCount > 0 && (
                                      <span class="badge">{unreadCount}</span>
                                    )} */}
                                        <img
                                          src={CRMOfficeCaller_active}
                                          alt=""
                                        />
                                      </>
                                    ) : (
                                      <>
                                        {unreadCount > 0 && (
                                          <span class="badge">
                                            {unreadCount}
                                          </span>
                                        )}
                                        <img src={CRMphone_iactive} alt="" />
                                      </>
                                    )}
                                  </Link>
                                </div>
                                <div
                                  className={
                                    currentRoute === "/AgentList" ||
                                    currentRoute === "/CRMCalls" ||
                                    currentRoute === "/Users" ||
                                    currentRoute === "/WhatsappSetting" ||
                                    currentRoute === "/NotCallClassification" ||
                                    currentRoute === "/FrequentCaller" ||
                                    currentRoute === "/ServiceRequest" ||
                                    currentRoute === "/ZeroCallList" ||
                                    currentRoute === "/AgentCallSummary" ||
                                    currentRoute === "/AssignCallUsers" ||
                                    currentRoute ===
                                      "/SalesInternationalcall" ||
                                    currentRoute === "/UploadCandidateOld" ||
                                    currentRoute === "/Supplier" ||
                                    currentRoute === "/WhatsappPromotion" ||
                                    currentRoute === "/Consultant"
                                      ? "menu-name activeColorBlue"
                                      : "menu-name"
                                  }
                                >
                                  CRM Phonebook
                                </div>
                              </div>

                              <div className="arrow">
                                {CRMPhoneBook ? (
                                  <img src={down_arrow} alt="" />
                                ) : (
                                  <img src={ic_blueUP_arrow} alt="" />
                                )}
                              </div>
                            </Link>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className={sidebar ? "sub-menus" : "none"}>
                              <Link
                                to="/AgentList"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                  getPrivateChat();
                                }}
                              >
                                {/* {unreadCount > 0 && <span class="badge">{unreadCount}</span>} */}
                                <div
                                  className={
                                    currentRoute === "/AgentList"
                                      ? "menu-name activeColorBlue"
                                      : "menu-name"
                                  }
                                  onClick={() => {
                                    // setActiveChat({});
                                    // setCurrentSChannel(null);
                                  }}
                                >
                                  Agent
                                </div>
                              </Link>
                              <Link
                                to="/CRMCalls"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                  getProjects();
                                }}
                              >
                                <div
                                  className={
                                    currentRoute === "/CRMCalls"
                                      ? "menu-name activeColorBlue"
                                      : "menu-name"
                                  }
                                  onClick={() => {
                                    // setActiveChat({});
                                    // setCurrentSChannel(null);
                                  }}
                                >
                                  Calls
                                </div>
                              </Link>
                              <Link
                                to="/Users"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                  getTeams();
                                }}
                              >
                                <div
                                  className={
                                    currentRoute === "/Users"
                                      ? "menu-name activeColorBlue"
                                      : "menu-name"
                                  }
                                  onClick={() => {
                                    // setActiveChat({});
                                    // setCurrentSChannel(null);
                                  }}
                                >
                                  Users
                                </div>
                              </Link>

                              <Link
                                to="/WhatsappSetting"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                  getCallHistory();
                                }}
                              >
                                <div
                                  className={
                                    currentRoute === "/WhatsappSetting"
                                      ? "menu-name activeColorBlue"
                                      : "menu-name"
                                  }
                                >
                                  Whatsapp Settings
                                </div>
                              </Link>

                              <Link
                                to="/NotCallClassification"
                                className="sub-menue-items"
                                // onClick={() => {
                                //   isMobile ? setSidebar(false) : setSidebar(true);
                                //   getCallHistory()
                                // }}
                              >
                                <div
                                  className={
                                    currentRoute === "/NotCallClassification"
                                      ? "menu-name activeColorBlue"
                                      : "menu-name"
                                  }
                                >
                                  Not Call Classification
                                </div>
                              </Link>

                              <Link
                                to="/AgentCallSummary"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                  getPrivateChat();
                                }}
                              >
                                {/* {unreadCount > 0 && <span class="badge">{unreadCount}</span>} */}
                                <div
                                  className={
                                    currentRoute === "/AgentCallSummary"
                                      ? "menu-name activeColorBlue"
                                      : "menu-name"
                                  }
                                  onClick={() => {
                                    // setActiveChat({});
                                    // setCurrentSChannel(null);
                                  }}
                                >
                                  Agent Call Summary
                                </div>
                              </Link>
                              {/* <Link
                            to="/AssignCallUsers"
                            className="sub-menue-items"
                            // onClick={() => {
                            //   isMobile ? setSidebar(false) : setSidebar(true);
                            //   getCallHistory()
                            // }}
                          >
                            <div
                              className={
                                currentRoute === "/AssignCallUsers"
                                  ? "menu-name activeColorBlue"
                                  : "menu-name"
                              }
                            >
                              Candidate (Process Data)
                            </div>
                          </Link> */}

                              <Link className="sub-menue-items">
                                <div
                                  className={
                                    currentRoute === "/CandidatesData" ||
                                    currentRoute === "/DownloadAgentReport" ||
                                    currentRoute === "/BulkCallView" ||
                                    currentRoute === "/UpdateUserDetails" ||
                                    currentRoute === "/UpdateUserDetails" ||
                                    currentRoute === "/AssignCallUsers" ||
                                    currentRoute === "/CallReport"
                                      ? // currentRoute === "/EmailReport" ||
                                        // currentRoute === "/LeadListCount"
                                        "menu-name activeColorBlue"
                                      : "menu-name"
                                  }
                                  onClick={() => {
                                    setCandidateOpen(!CandidateOpen);
                                    // setActiveChat({});
                                    // setCurrentSChannel(null);
                                  }}
                                >
                                  Candidate (Process Data)
                                  <div className="arrow">
                                    {SettingsOpen ? (
                                      <img src={down_arrow} alt="" />
                                    ) : (
                                      <img src={ic_blueUP_arrow} alt="" />
                                    )}
                                  </div>
                                </div>
                              </Link>
                              {CandidateOpen ? (
                                <div
                                  style={{
                                    marginLeft: "20px",
                                    borderLeft: "1px solid  #53b7e8",
                                  }}
                                >
                                  <Link
                                    style={{}}
                                    // to="/LeadManagement"
                                    className="sub-menue-items"
                                    onClick={() => {
                                      isMobile
                                        ? setSidebar(false)
                                        : setSidebar(true);
                                      getProjects();
                                    }}
                                  >
                                    <Link
                                      style={{}}
                                      to="/AssignCallUsers"
                                      // className="sub-menue-items"
                                      // onClick={() => {
                                      //   isMobile
                                      //     ? setSidebar(false)
                                      //     : setSidebar(true);
                                      //   getProjects();
                                      // }}
                                    >
                                      <div
                                        className={
                                          currentRoute === "/AssignCallUsers"
                                            ? "menu-name activeColorBlue"
                                            : "menu-name"
                                        }
                                        onClick={() => {
                                          // setActiveChat({});
                                          // setCurrentSChannel(null);
                                        }}
                                      >
                                        Call Assigning
                                      </div>
                                    </Link>
                                    <Link
                                      style={{}}
                                      to="/CandidatesData"
                                      // className="sub-menue-items"
                                      // onClick={() => {
                                      //   isMobile
                                      //     ? setSidebar(false)
                                      //     : setSidebar(true);
                                      //   getProjects();
                                      // }}
                                    >
                                      <div
                                        className={
                                          currentRoute === "/CandidatesData"
                                            ? "menu-name activeColorBlue"
                                            : "menu-name"
                                        }
                                        onClick={() => {
                                          // setActiveChat({});
                                          // setCurrentSChannel(null);
                                        }}
                                      >
                                        View Data
                                      </div>
                                    </Link>
                                    <Link
                                      style={{}}
                                      to="/DownloadAgentReport"
                                      // className="sub-menue-items"
                                      // onClick={() => {
                                      //   isMobile
                                      //     ? setSidebar(false)
                                      //     : setSidebar(true);
                                      //   getProjects();
                                      // }}
                                    >
                                      <div
                                        className={
                                          currentRoute ===
                                          "/DownloadAgentReport"
                                            ? "menu-name activeColorBlue"
                                            : "menu-name"
                                        }
                                        onClick={() => {
                                          // setActiveChat({});
                                          // setCurrentSChannel(null);
                                        }}
                                      >
                                        Download Data
                                      </div>
                                    </Link>
                                    <Link
                                      style={{}}
                                      to="/BulkCallView"
                                      // className="sub-menue-items"
                                      // onClick={() => {
                                      //   isMobile
                                      //     ? setSidebar(false)
                                      //     : setSidebar(true);
                                      //   getProjects();
                                      // }}
                                    >
                                      <div
                                        className={
                                          currentRoute === "/BulkCallView"
                                            ? "menu-name activeColorBlue"
                                            : "menu-name"
                                        }
                                        onClick={() => {
                                          // setActiveChat({});
                                          // setCurrentSChannel(null);
                                        }}
                                      >
                                        Re-Sign Candidate
                                      </div>
                                    </Link>

                                    <Link
                                      style={{}}
                                      to="/CallReport"
                                      // className="sub-menue-items"
                                      // onClick={() => {
                                      //   isMobile
                                      //     ? setSidebar(false)
                                      //     : setSidebar(true);
                                      //   getProjects();
                                      // }}
                                    >
                                      <div
                                        className={
                                          currentRoute === "/UpdateUserDetails"
                                            ? "menu-name activeColorBlue"
                                            : "menu-name"
                                        }
                                        onClick={() => {
                                          // setActiveChat({});
                                          // setCurrentSChannel(null);
                                        }}
                                      >
                                        Call Report
                                      </div>
                                    </Link>
                                  </Link>
                                </div>
                              ) : null}

                              <Link
                                to="/SalesInternationalcall"
                                className="sub-menue-items"
                                // onClick={() => {
                                //   isMobile ? setSidebar(false) : setSidebar(true);
                                //   getCallHistory()
                                // }}
                              >
                                <div
                                  className={
                                    currentRoute === "/SalesInternationalcall"
                                      ? "menu-name activeColorBlue"
                                      : "menu-name"
                                  }
                                >
                                  Sales International call
                                </div>
                              </Link>

                              <Link
                                to="/UploadCandidateOld"
                                className="sub-menue-items"
                                // onClick={() => {
                                //   isMobile ? setSidebar(false) : setSidebar(true);
                                //   getCallHistory()
                                // }}
                              >
                                <div
                                  className={
                                    currentRoute === "/UploadCandidateOld"
                                      ? "menu-name activeColorBlue"
                                      : "menu-name"
                                  }
                                >
                                  Candidate Raw Data
                                </div>
                              </Link>
                              <Link
                                to="/FrequentCaller"
                                className="sub-menue-items"
                                // onClick={() => {
                                //   isMobile ? setSidebar(false) : setSidebar(true);
                                //   getCallHistory()
                                // }}
                              >
                                <div
                                  className={
                                    currentRoute === "/FrequentCaller"
                                      ? "menu-name activeColorBlue"
                                      : "menu-name"
                                  }
                                >
                                  Frequent Caller
                                </div>
                              </Link>
                              <Link
                                to="/ZeroCallList"
                                className="sub-menue-items"
                                // onClick={() => {
                                //   isMobile ? setSidebar(false) : setSidebar(true);
                                //   getCallHistory()
                                // }}
                              >
                                <div
                                  className={
                                    currentRoute === "/ZeroCallList"
                                      ? "menu-name activeColorBlue"
                                      : "menu-name"
                                  }
                                >
                                  Zero Call List
                                </div>
                              </Link>
                              <Link
                                to="/ServiceRequest"
                                className="sub-menue-items"
                                // onClick={() => {
                                //   isMobile ? setSidebar(false) : setSidebar(true);
                                //   getCallHistory()
                                // }}
                              >
                                <div
                                  className={
                                    currentRoute === "/ServiceRequest"
                                      ? "menu-name activeColorBlue"
                                      : "menu-name"
                                  }
                                >
                                  Service Request
                                </div>
                              </Link>

                              <Link
                                to="/Consultant"
                                className="sub-menue-items"
                                // onClick={() => {
                                //   isMobile ? setSidebar(false) : setSidebar(true);
                                //   getCallHistory()
                                // }}
                              >
                                <div
                                  className={
                                    currentRoute === "/Consultant"
                                      ? "menu-name activeColorBlue"
                                      : "menu-name"
                                  }
                                >
                                  Consultant
                                </div>
                              </Link>
                              <Link
                                to="/Supplier"
                                className="sub-menue-items"
                                // onClick={() => {
                                //   isMobile ? setSidebar(false) : setSidebar(true);
                                //   getCallHistory()
                                // }}
                              >
                                <div
                                  className={
                                    currentRoute === "/Supplier"
                                      ? "menu-name activeColorBlue"
                                      : "menu-name"
                                  }
                                >
                                  Supplier
                                </div>
                              </Link>
                              <Link
                                to="/WhatsappPromotion"
                                className="sub-menue-items"
                                // onClick={() => {
                                //   isMobile ? setSidebar(false) : setSidebar(true);
                                //   getCallHistory()
                                // }}
                              >
                                <div
                                  className={
                                    currentRoute === "/WhatsappPromotion"
                                      ? "menu-name activeColorBlue"
                                      : "menu-name"
                                  }
                                >
                                  WhatsappPromotion
                                </div>
                              </Link>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </div>

                      {/* ================> END CRM Phonebook <============== */}

                      {/* =================> START CRM Phonebook <============= */}

                      <div className="with-subMenu">
                        <Accordion.Item eventKey="15">
                          <Accordion.Header className="menus-items">
                            <Link
                              to="#"
                              className="icon-menu-name-arrow"
                              onClick={() => {
                                handleCRMOfficeCaller();
                                // setAttendance(false);
                                // setTimesheet(false);
                                // setWalletCheck(false);
                                // setTaskManagement(false);
                                // setNotifications(false);
                                // setProjectMan(false);
                              }}
                            >
                              <div className="menu-left">
                                <div className="icon-left">
                                  <Link to="/chat">
                                    {currentRoute === "/LeadManagement" ||
                                    currentRoute === "/projects" ||
                                    currentRoute === "/Call" ||
                                    currentRoute === "/WhatsappSetting" ||
                                    currentRoute === "/AgentCallSummary" ||
                                    currentRoute === "/Campaign" ||
                                    currentRoute === "/Admin" ||
                                    currentRoute === "/QueryCases" ||
                                    currentRoute === "/InvalidLeads" ||
                                    currentRoute === "/EmailReport" ||
                                    currentRoute === "/CrmPushNotification" ||
                                    currentRoute === "/GoogleAnalytics" ||
                                    currentRoute === "/CrmWebsite" ||
                                    currentRoute === "/CrmSendgrid" ||
                                    currentRoute === "/Domains" ||
                                    currentRoute ===
                                      "/CategoriesOfficeCaller" ||
                                    currentRoute === "/CrmApp" ||
                                    currentRoute === "/NonFunctionalFeatures" ||
                                    currentRoute === "/BulkUpload" ||
                                    currentRoute === "/ContactCustomGroup" ||
                                    currentRoute === "/DefaultGroup" ? (
                                      <>
                                        {/* {unreadCount > 0 && (
                                      <span class="badge">{unreadCount}</span>
                                    )} */}
                                        <img
                                          src={CRMOfficeCaller_active}
                                          alt=""
                                        />
                                      </>
                                    ) : (
                                      <>
                                        {unreadCount > 0 && (
                                          <span class="badge">
                                            {unreadCount}
                                          </span>
                                        )}
                                        <img src={CRMphone_iactive} alt="" />
                                      </>
                                    )}
                                  </Link>
                                </div>
                                <div
                                  className={
                                    currentRoute === "/LeadManagement" ||
                                    currentRoute === "/InvalidLeads" ||
                                    currentRoute === "/EmailReport" ||
                                    currentRoute === "/Campaign" ||
                                    currentRoute === "/Admin" ||
                                    currentRoute === "/QueryCases" ||
                                    currentRoute === "/CrmPushNotification" ||
                                    currentRoute === "/GoogleAnalytics" ||
                                    currentRoute === "/CrmWebsite" ||
                                    currentRoute === "/CrmSendgrid" ||
                                    currentRoute ===
                                      "/CategoriesOfficeCaller" ||
                                    currentRoute === "/CrmApp" ||
                                    currentRoute === "/BulkUpload" ||
                                    currentRoute === "/Domains" ||
                                    currentRoute === "/NonFunctionalFeatures" ||
                                    currentRoute === "/ContactCustomGroup" ||
                                    currentRoute === "/DefaultGroup" ||
                                    currentRoute === "/LeadListCount"
                                      ? "menu-name activeColorBlue"
                                      : "menu-name"
                                  }
                                >
                                  CRM Office Caller
                                </div>
                              </div>
                              <div className="arrow">
                                {CRMOfficeCaller ? (
                                  <img src={down_arrow} alt="" />
                                ) : (
                                  <img src={ic_blueUP_arrow} alt="" />
                                )}
                              </div>
                            </Link>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className={sidebar ? "sub-menus" : "none"}>
                              <Link className="sub-menue-items">
                                <div
                                  className={
                                    currentRoute === "/LeadManagement" ||
                                    currentRoute === "/InvalidLeads" ||
                                    currentRoute === "/EmailReport" ||
                                    currentRoute === "/LeadListCount"
                                      ? "menu-name activeColorBlue"
                                      : "menu-name"
                                  }
                                  onClick={() => {
                                    setLeadOpen(!LeadOpen);
                                    // setActiveChat({});
                                    // setCurrentSChannel(null);
                                  }}
                                >
                                  LeadManagement
                                  <div className="arrow">
                                    {LeadOpen ? (
                                      <img src={down_arrow} alt="" />
                                    ) : (
                                      <img src={ic_blueUP_arrow} alt="" />
                                    )}
                                  </div>
                                </div>
                              </Link>
                              {LeadOpen ? (
                                <div
                                  style={{
                                    marginLeft: "20px",
                                    borderLeft: "1px solid  #53b7e8",
                                  }}
                                >
                                  <Link
                                    style={{}}
                                    // to="/LeadManagement"
                                    className="sub-menue-items"
                                    onClick={() => {
                                      isMobile
                                        ? setSidebar(false)
                                        : setSidebar(true);
                                      getProjects();
                                    }}
                                  >
                                    <Link
                                      style={{}}
                                      to="/LeadManagement"
                                      // className="sub-menue-items"
                                      // onClick={() => {
                                      //   isMobile
                                      //     ? setSidebar(false)
                                      //     : setSidebar(true);
                                      //   getProjects();
                                      // }}
                                    >
                                      <div
                                        className={
                                          currentRoute === "/LeadManagement"
                                            ? "menu-name activeColorBlue"
                                            : "menu-name"
                                        }
                                        onClick={() => {
                                          // setActiveChat({});
                                          // setCurrentSChannel(null);
                                        }}
                                      >
                                        Leads
                                      </div>
                                    </Link>
                                    <Link
                                      style={{}}
                                      to="/InvalidLeads"
                                      // className="sub-menue-items"
                                      // onClick={() => {
                                      //   isMobile
                                      //     ? setSidebar(false)
                                      //     : setSidebar(true);
                                      //   getProjects();
                                      // }}
                                    >
                                      <div
                                        className={
                                          currentRoute === "/InvalidLeads"
                                            ? "menu-name activeColorBlue"
                                            : "menu-name"
                                        }
                                        onClick={() => {
                                          // setActiveChat({});
                                          // setCurrentSChannel(null);
                                        }}
                                      >
                                        Invalid Leads
                                      </div>
                                    </Link>
                                    <Link
                                      style={{}}
                                      to="/EmailReport"
                                      // className="sub-menue-items"
                                      // onClick={() => {
                                      //   isMobile
                                      //     ? setSidebar(false)
                                      //     : setSidebar(true);
                                      //   getProjects();
                                      // }}
                                    >
                                      <div
                                        className={
                                          currentRoute === "/EmailReport"
                                            ? "menu-name activeColorBlue"
                                            : "menu-name"
                                        }
                                        onClick={() => {
                                          // setActiveChat({});
                                          // setCurrentSChannel(null);
                                        }}
                                      >
                                        Email Report
                                      </div>
                                    </Link>
                                  </Link>
                                </div>
                              ) : null}

                              <Link
                                to="/Campaign"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                  getCallHistory();
                                }}
                              >
                                <div
                                  className={
                                    currentRoute === "/Campaign"
                                      ? "menu-name activeColorBlue"
                                      : "menu-name"
                                  }
                                >
                                  Campaign
                                </div>
                              </Link>

                              <Link
                                to="/QueryCases"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                  getCallHistory();
                                }}
                              >
                                <div
                                  className={
                                    currentRoute === "/QueryCases"
                                      ? "menu-name activeColorBlue"
                                      : "menu-name"
                                  }
                                >
                                  Query Management
                                </div>
                              </Link>

                              <Link className="sub-menue-items">
                                <div
                                  className={
                                    currentRoute === "/CrmPushNotification" ||
                                    currentRoute === "/GoogleAnalytics" ||
                                    currentRoute === "/CrmWebsite" ||
                                    currentRoute === "/CrmSendgrid"
                                      ? // currentRoute === "/EmailReport" ||
                                        // currentRoute === "/LeadListCount"
                                        "menu-name activeColorBlue"
                                      : "menu-name"
                                  }
                                  onClick={() => {
                                    setSettingsOpen(!SettingsOpen);
                                    // setActiveChat({});
                                    // setCurrentSChannel(null);
                                  }}
                                >
                                  Settings
                                  <div className="arrow">
                                    {SettingsOpen ? (
                                      <img src={down_arrow} alt="" />
                                    ) : (
                                      <img src={ic_blueUP_arrow} alt="" />
                                    )}
                                  </div>
                                </div>
                              </Link>
                              {SettingsOpen ? (
                                <div
                                  style={{
                                    marginLeft: "20px",
                                    borderLeft: "1px solid  #53b7e8",
                                  }}
                                >
                                  <Link
                                    style={{}}
                                    // to="/LeadManagement"
                                    className="sub-menue-items"
                                    onClick={() => {
                                      isMobile
                                        ? setSidebar(false)
                                        : setSidebar(true);
                                      getProjects();
                                    }}
                                  >
                                    <Link
                                      style={{}}
                                      to="/CrmPushNotification"
                                      // className="sub-menue-items"
                                      // onClick={() => {
                                      //   isMobile
                                      //     ? setSidebar(false)
                                      //     : setSidebar(true);
                                      //   getProjects();
                                      // }}
                                    >
                                      <div
                                        className={
                                          currentRoute ===
                                          "/CrmPushNotification"
                                            ? "menu-name activeColorBlue"
                                            : "menu-name"
                                        }
                                        onClick={() => {
                                          // setActiveChat({});
                                          // setCurrentSChannel(null);
                                        }}
                                      >
                                        Push Notification
                                      </div>
                                    </Link>
                                    <Link
                                      style={{}}
                                      to="/GoogleAnalytics"
                                      // className="sub-menue-items"
                                      // onClick={() => {
                                      //   isMobile
                                      //     ? setSidebar(false)
                                      //     : setSidebar(true);
                                      //   getProjects();
                                      // }}
                                    >
                                      <div
                                        className={
                                          currentRoute === "/GoogleAnalytics"
                                            ? "menu-name activeColorBlue"
                                            : "menu-name"
                                        }
                                        onClick={() => {
                                          // setActiveChat({});
                                          // setCurrentSChannel(null);
                                        }}
                                      >
                                        Google Analytics
                                      </div>
                                    </Link>
                                    <Link
                                      style={{}}
                                      to="/CrmWebsite"
                                      // className="sub-menue-items"
                                      // onClick={() => {
                                      //   isMobile
                                      //     ? setSidebar(false)
                                      //     : setSidebar(true);
                                      //   getProjects();
                                      // }}
                                    >
                                      <div
                                        className={
                                          currentRoute === "/CrmWebsite"
                                            ? "menu-name activeColorBlue"
                                            : "menu-name"
                                        }
                                        onClick={() => {
                                          // setActiveChat({});
                                          // setCurrentSChannel(null);
                                        }}
                                      >
                                        Website
                                      </div>
                                    </Link>
                                    <Link
                                      style={{}}
                                      to="/CrmSendgrid"
                                      // className="sub-menue-items"
                                      // onClick={() => {
                                      //   isMobile
                                      //     ? setSidebar(false)
                                      //     : setSidebar(true);
                                      //   getProjects();
                                      // }}
                                    >
                                      <div
                                        className={
                                          currentRoute === "/CrmSendgrid"
                                            ? "menu-name activeColorBlue"
                                            : "menu-name"
                                        }
                                        onClick={() => {
                                          // setActiveChat({});
                                          // setCurrentSChannel(null);
                                        }}
                                      >
                                        Sendgrid
                                      </div>
                                    </Link>
                                  </Link>
                                </div>
                              ) : null}
                              <Link
                                to="/Admin"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                  getCallHistory();
                                }}
                              >
                                <div
                                  className={
                                    currentRoute === "/Admin"
                                      ? "menu-name activeColorBlue"
                                      : "menu-name"
                                  }
                                >
                                  Admin
                                </div>
                              </Link>

                              <Link className="sub-menue-items">
                                <div
                                  className={
                                    currentRoute ===
                                      "/CategoriesOfficeCaller" ||
                                    currentRoute === "/CrmApp" ||
                                    currentRoute === "/BulkUpload" ||
                                    currentRoute === "/Domains" ||
                                    currentRoute === "/NonFunctionalFeatures"
                                      ? // currentRoute === "/LeadListCount"
                                        "menu-name activeColorBlue"
                                      : "menu-name"
                                  }
                                  onClick={() => {
                                    setDomainOpen(!DomainOpen);
                                    // setActiveChat({});
                                    // setCurrentSChannel(null);
                                  }}
                                >
                                  Domains And Apps
                                  <div className="arrow">
                                    {DomainOpen ? (
                                      <img src={down_arrow} alt="" />
                                    ) : (
                                      <img src={ic_blueUP_arrow} alt="" />
                                    )}
                                  </div>
                                </div>
                              </Link>

                              {DomainOpen ? (
                                <div
                                  style={{
                                    marginLeft: "20px",
                                    borderLeft: "1px solid  #53b7e8",
                                  }}
                                >
                                  <Link
                                    style={{}}
                                    // to="/LeadManagement"
                                    className="sub-menue-items"
                                    onClick={() => {
                                      isMobile
                                        ? setSidebar(false)
                                        : setSidebar(true);
                                      getProjects();
                                    }}
                                  >
                                    <Link
                                      style={{}}
                                      to="/Domains"
                                      //     className={
                                      //   currentRoute === "/LeadManagement" ||
                                      //   currentRoute === "/InvalidLeads" ||
                                      //   currentRoute === "/EmailReport"
                                      //     ? "menu-name activeColorBlue"
                                      //     : "menu-name"
                                      // }
                                    >
                                      <div
                                        className={
                                          currentRoute === "/Domains"
                                            ? "menu-name activeColorBlue"
                                            : "menu-name"
                                        }
                                        onClick={() => {
                                          // setActiveChat({});
                                          // setCurrentSChannel(null);
                                        }}
                                      >
                                        Domains
                                      </div>
                                    </Link>
                                    <Link
                                      style={{}}
                                      to="/CategoriesOfficeCaller"
                                      // className="sub-menue-items"
                                      // onClick={() => {
                                      //   isMobile
                                      //     ? setSidebar(false)
                                      //     : setSidebar(true);
                                      //   getProjects();
                                      // }}
                                    >
                                      <div
                                        className={
                                          currentRoute ===
                                          "/CategoriesOfficeCaller"
                                            ? "menu-name activeColorBlue"
                                            : "menu-name"
                                        }
                                        onClick={() => {
                                          // setActiveChat({});
                                          // setCurrentSChannel(null);
                                        }}
                                      >
                                        Categories
                                      </div>
                                    </Link>
                                    <Link
                                      style={{}}
                                      to="/CrmApp"
                                      // className="sub-menue-items"
                                      // onClick={() => {
                                      //   isMobile
                                      //     ? setSidebar(false)
                                      //     : setSidebar(true);
                                      //   getProjects();
                                      // }}
                                    >
                                      <div
                                        className={
                                          currentRoute === "/CrmApp"
                                            ? "menu-name activeColorBlue"
                                            : "menu-name"
                                        }
                                        onClick={() => {
                                          // setActiveChat({});
                                          // setCurrentSChannel(null);
                                        }}
                                      >
                                        Apps
                                      </div>
                                    </Link>
                                    <Link
                                      style={{}}
                                      to="/NonFunctionalFeatures"
                                      // className="sub-menue-items"
                                      // onClick={() => {
                                      //   isMobile
                                      //     ? setSidebar(false)
                                      //     : setSidebar(true);
                                      //   getProjects();
                                      // }}
                                    >
                                      <div
                                        className={
                                          currentRoute ===
                                          "/NonFunctionalFeatures"
                                            ? "menu-name activeColorBlue"
                                            : "menu-name"
                                        }
                                        onClick={() => {
                                          // setActiveChat({});
                                          // setCurrentSChannel(null);
                                        }}
                                      >
                                        Non-Functional Features
                                      </div>
                                    </Link>

                                    <Link
                                      style={{}}
                                      to="/BulkUpload"
                                      // className="sub-menue-items"
                                      // onClick={() => {
                                      //   isMobile
                                      //     ? setSidebar(false)
                                      //     : setSidebar(true);
                                      //   getProjects();
                                      // }}
                                    >
                                      <div
                                        className={
                                          currentRoute === "/BulkUpload"
                                            ? "menu-name activeColorBlue"
                                            : "menu-name"
                                        }
                                        onClick={() => {
                                          // setActiveChat({});
                                          // setCurrentSChannel(null);
                                        }}
                                      >
                                        Bulk Upload
                                      </div>
                                    </Link>
                                  </Link>
                                </div>
                              ) : null}

                              <Link className="sub-menue-items">
                                <div
                                  className={
                                    currentRoute === "/ContactCustomGroup" ||
                                    currentRoute === "/DefaultGroup"
                                      ? // currentRoute === "/LeadListCount"
                                        "menu-name activeColorBlue"
                                      : "menu-name"
                                  }
                                  onClick={() => {
                                    setContactCustomGroup(!ContactCustomGroup);
                                    // setActiveChat({});
                                    // setCurrentSChannel(null);
                                  }}
                                >
                                  Contact Group Management
                                  <div className="arrow">
                                    {ContactCustomGroup ? (
                                      <img src={down_arrow} alt="" />
                                    ) : (
                                      <img src={ic_blueUP_arrow} alt="" />
                                    )}
                                  </div>
                                </div>
                              </Link>

                              {ContactCustomGroup ? (
                                <div
                                  style={{
                                    marginLeft: "20px",
                                    borderLeft: "1px solid  #53b7e8",
                                  }}
                                >
                                  <Link
                                    style={{}}
                                    // to="/LeadManagement"
                                    className="sub-menue-items"
                                    onClick={() => {
                                      isMobile
                                        ? setSidebar(false)
                                        : setSidebar(true);
                                      getProjects();
                                    }}
                                  >
                                    <Link
                                      style={{}}
                                      to="/ContactCustomGroup"
                                      //     className={
                                      //   currentRoute === "/LeadManagement" ||
                                      //   currentRoute === "/InvalidLeads" ||
                                      //   currentRoute === "/EmailReport"
                                      //     ? "menu-name activeColorBlue"
                                      //     : "menu-name"
                                      // }
                                    >
                                      <div
                                        className={
                                          currentRoute === "/ContactCustomGroup"
                                            ? "menu-name activeColorBlue"
                                            : "menu-name"
                                        }
                                        onClick={() => {
                                          // setActiveChat({});
                                          // setCurrentSChannel(null);
                                        }}
                                      >
                                        Contact Custom Group
                                      </div>
                                    </Link>
                                    <Link
                                      style={{}}
                                      to="/DefaultGroup"
                                      // className="sub-menue-items"
                                      // onClick={() => {
                                      //   isMobile
                                      //     ? setSidebar(false)
                                      //     : setSidebar(true);
                                      //   getProjects();
                                      // }}
                                    >
                                      <div
                                        className={
                                          currentRoute === "/DefaultGroup"
                                            ? "menu-name activeColorBlue"
                                            : "menu-name"
                                        }
                                        onClick={() => {
                                          // setActiveChat({});
                                          // setCurrentSChannel(null);
                                        }}
                                      >
                                        Default Groups
                                      </div>
                                    </Link>
                                  </Link>
                                </div>
                              ) : null}
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </div>
                    </>
                  )}
                  {/* ================> END CRM Phonebook <============== */}

                  {/* =================> START User Management <============= */}

                  {permissions && permissions["View Roles"] && (
                    <div className="with-subMenu">
                      <Accordion.Item eventKey="4">
                        <Accordion.Header className="menus-items">
                          <Link
                            to="#"
                            className="icon-menu-name-arrow"
                            onClick={() => {
                              setWalletCheck(false);
                              handleUserMan();
                              setTaskManagement(false);
                              setAttendance(false);
                              setApproveLeave(false);
                              setLeave(false);
                              setDashboard(false);
                              setTimesheet(false);
                              setNotifications(false);
                              setCommunication(false);
                              setProjectMan(false);
                            }}
                          >
                            <div className="menu-left">
                              <div className="icon-left">
                                <Link to="/Role">
                                  {currentRoute === "/Role" ||
                                  currentRoute === "/AssignRole" ||
                                  currentRoute === "/Permission" ? (
                                    <img src={ActiveMan} alt="" />
                                  ) : (
                                    <img src={taskmanag_icon} alt="" />
                                  )}
                                </Link>
                              </div>
                              <div
                                className={
                                  currentRoute === "/Role" ||
                                  currentRoute === "/AssignRole" ||
                                  currentRoute === "/Permission"
                                    ? "menu-name activeColorBlue"
                                    : "menu-name"
                                }
                              >
                                User <br /> Management
                              </div>
                            </div>
                            <div className="arrow">
                              {TaskManagement ? (
                                <img src={down_arrow} alt="" />
                              ) : (
                                <img src={ic_blueUP_arrow} alt="" />
                              )}
                            </div>
                          </Link>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className={sidebar ? "sub-menus" : "none"}>
                            <Link
                              to="/Role"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              <div
                                className={
                                  currentRoute === "/Role"
                                    ? "menu-name activeColorBlue"
                                    : "menu-name"
                                }
                              >
                                Role
                              </div>
                            </Link>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </div>
                  )}

                  {/* =================> START Client Management <============= */}
                  {permissions && permissions["View Client Management"] ? (
                    <>
                      <div className="with-subMenu">
                        <Accordion.Item eventKey="5">
                          <Accordion.Header className="menus-items ">
                            <Link
                              to="#"
                              className="icon-menu-name-arrow"
                              onClick={() => {
                                handleNotifications();
                                setWalletCheck(false);
                                setAttendance(false);
                                setTimesheet(false);
                                setTaskManagement(false);
                                setCommunication(false);
                                setProjectMan(false);
                              }}
                            >
                              <div className="menu-left">
                                <div className="icon-left">
                                  <Link to="/ClientManagement">
                                    {currentRoute === "/ClientManagement" ? (
                                      <img
                                        src={ClientManActive}
                                        alt=""
                                        className="mt-1"
                                      />
                                    ) : (
                                      <img
                                        src={ClientMan}
                                        alt=""
                                        className="mt-1"
                                      />
                                    )}
                                  </Link>
                                </div>
                                <div
                                  className={
                                    currentRoute === "/ClientManagement"
                                      ? "menu-name activeColorBlue"
                                      : "menu-name"
                                  }
                                >
                                  Client <br />
                                  Management
                                </div>
                              </div>

                              <div className="arrow">
                                {Notifications ? (
                                  <img src={down_arrow} alt="" />
                                ) : (
                                  <img src={ic_blueUP_arrow} alt="" />
                                )}
                              </div>
                            </Link>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className={sidebar ? "sub-menus" : "none"}>
                              <Link
                                to="/ClientManagement"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              ></Link>
                              <Link
                                to="/ClientManagement"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                  permissions["View Client Management"] && (
                                    <div
                                      className={
                                        currentRoute === "/ClientManagement"
                                          ? "menu-name activeColorBlue"
                                          : "menu-name"
                                      }
                                    >
                                      Client Management
                                    </div>
                                  )}
                              </Link>
                              <Link
                                to="/holidaysandevents"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                  permissions["Holiday And Events"] && (
                                    <div
                                      className={
                                        currentRoute === "/holidaysandevents"
                                          ? "menu-name activeColorBlue"
                                          : "menu-name"
                                      }
                                    >
                                      Holidays And Events
                                    </div>
                                  )}
                              </Link>
                              <Link
                                to="/TearmsAmdCondiation"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                  permissions["Terms & Conditions"] && (
                                    <div
                                      className={
                                        currentRoute === "/TearmsAmdCondiation"
                                          ? "menu-name activeColorBlue"
                                          : "menu-name"
                                      }
                                    >
                                      Terms & Conditions
                                    </div>
                                  )}
                              </Link>
                              <Link
                                to="/PrivacyPolicy"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                  permissions["Privacy Policy"] && (
                                    <div
                                      className={
                                        currentRoute === "/PrivacyPolicy"
                                          ? "menu-name activeColorBlue"
                                          : "menu-name"
                                      }
                                    >
                                      Privacy Policy
                                    </div>
                                  )}
                              </Link>
                              <Link
                                to="/AboutOrganziation"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                  permissions["View Organization"] && (
                                    <div
                                      className={
                                        currentRoute === "/AboutOrganziation" ||
                                        currentRoute === "/AboutOrganziation"
                                          ? "menu-name activeColorBlue"
                                          : "menu-name"
                                      }
                                    >
                                      About Organization
                                    </div>
                                  )}
                              </Link>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </div>
                    </>
                  ) : null}

                  {/* ================> END User Management <============== */}

                  {/* =================> START Task Management <============= */}
                  {permissions &&
                  (permissions["View Task"] ||
                    permissions["View Task List"] ||
                    permissions["View Task Allocation"] ||
                    permissions["View Abort Task"] ||
                    permissions["View Hold Task"] ||
                    permissions["View Complete Task"] ||
                    permissions["View Team Task"] ||
                    permissions["View roject Task"] ||
                    permissions["View Task Approve"] ||
                    permissions["View Standard Task"]) ? (
                    <>
                      <div className="with-subMenu">
                        <Accordion.Item eventKey="6">
                          <Accordion.Header className="menus-items ">
                            <Link
                              to="#"
                              className="icon-menu-name-arrow"
                              onClick={() => {
                                handleTaskManagement();
                                setWalletCheck(false);
                                setAttendance(false);
                                setApproveLeave(false);
                                setLeave(false);
                                setDashboard(false);
                                setTimesheet(false);
                                setNotifications(false);
                                setCommunication(false);
                                setProjectMan(false);
                              }}
                            >
                              <div className="menu-left">
                                <div className="icon-left">
                                  <Link to="/tasklist">
                                    {currentRoute === "/tasklist" ||
                                    currentRoute === "/teamtask" ||
                                    currentRoute === "/projecttask" ||
                                    currentRoute === "/TaskApprove" ||
                                    currentRoute === "/AbortTask" ||
                                    currentRoute === "/CompleteTask" ||
                                    currentRoute === "/StandardTask" ||
                                    currentRoute === "/HoldTask" ||
                                    currentRoute === "/Task" ||
                                    currentRoute === "/TaskAllocation" ? (
                                      <img src={taskmanag_icon_active} alt="" />
                                    ) : (
                                      <img src={taskmanag_icon} alt="" />
                                    )}
                                  </Link>
                                </div>
                                <div
                                  className={
                                    currentRoute === "/tasklist" ||
                                    currentRoute === "/teamtask" ||
                                    currentRoute === "/projecttask" ||
                                    currentRoute === "/TaskApprove" ||
                                    currentRoute === "/AbortTask" ||
                                    currentRoute === "/CompleteTask" ||
                                    currentRoute === "/StandardTask" ||
                                    currentRoute === "/HoldTask" ||
                                    currentRoute === "/Task" ||
                                    currentRoute === "/TaskAllocation"
                                      ? "menu-name activeColorBlue"
                                      : "menu-name"
                                  }
                                >
                                  Task <br /> Management
                                </div>
                              </div>

                              <div className="arrow">
                                {TaskManagement ? (
                                  <img src={down_arrow} alt="" />
                                ) : (
                                  <img src={ic_blueUP_arrow} alt="" />
                                )}
                              </div>
                            </Link>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className={sidebar ? "sub-menus" : "none"}>
                              <Link
                                to="/Task"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions && permissions["View Task"] ? (
                                  <div
                                    className={
                                      currentRoute === "/Task"
                                        ? "menu-name activeColorBlue"
                                        : "menu-name"
                                    }
                                  >
                                    Task
                                  </div>
                                ) : null}
                              </Link>
                              <Link
                                to="/tasklist"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                permissions["View Task List"] ? (
                                  <div
                                    className={
                                      currentRoute === "/tasklist"
                                        ? "menu-name activeColorBlue"
                                        : "menu-name"
                                    }
                                  >
                                    Task List
                                  </div>
                                ) : null}
                              </Link>
                              <Link
                                to="/TaskAllocation"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                permissions["View Task Allocation"] ? (
                                  <div
                                    className={
                                      currentRoute === "/TaskAllocation"
                                        ? "menu-name activeColorBlue"
                                        : "menu-name"
                                    }
                                  >
                                    Task Allocation
                                  </div>
                                ) : null}
                              </Link>
                              <Link
                                to="/AbortTask"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                permissions["View Abort Task"] ? (
                                  <div
                                    className={
                                      currentRoute === "/AbortTask"
                                        ? "menu-name activeColorBlue"
                                        : "menu-name"
                                    }
                                  >
                                    Abort Task
                                  </div>
                                ) : null}
                              </Link>
                              <Link
                                to="/HoldTask"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                permissions["View Hold Task"] ? (
                                  <div
                                    className={
                                      currentRoute === "/HoldTask"
                                        ? "menu-name activeColorBlue"
                                        : "menu-name"
                                    }
                                  >
                                    Hold Task
                                  </div>
                                ) : null}
                              </Link>
                              <Link
                                to="/CompleteTask"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                permissions["View Complete Task"] ? (
                                  <div
                                    className={
                                      currentRoute === "/CompleteTask"
                                        ? "menu-name activeColorBlue"
                                        : "menu-name"
                                    }
                                  >
                                    Complete Task
                                  </div>
                                ) : null}
                              </Link>
                              <Link
                                to="/teamtask"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                  permissions["View Team Task"] && (
                                    <div
                                      className={
                                        currentRoute === "/teamtask"
                                          ? "menu-name activeColorBlue"
                                          : "menu-name"
                                      }
                                    >
                                      Team Task
                                    </div>
                                  )}
                              </Link>
                              <Link
                                to="/projecttask"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                  permissions["View Project Task"] && (
                                    <div
                                      className={
                                        currentRoute === "/projecttask"
                                          ? "menu-name activeColorBlue"
                                          : "menu-name"
                                      }
                                    >
                                      Project Task
                                    </div>
                                  )}
                              </Link>
                              <Link
                                to="/TaskApprove"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                  permissions["View Task Approve"] && (
                                    <div
                                      className={
                                        currentRoute === "/TaskApprove"
                                          ? "menu-name activeColorBlue"
                                          : "menu-name"
                                      }
                                    >
                                      Task Approve
                                    </div>
                                  )}
                              </Link>
                              <Link
                                to="/StandardTask"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                permissions["View Standard Task"] ? (
                                  <div
                                    className={
                                      currentRoute === "/StandardTask"
                                        ? "menu-name activeColorBlue"
                                        : "menu-name"
                                    }
                                  >
                                    Standard Task
                                  </div>
                                ) : null}
                              </Link>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </div>
                    </>
                  ) : null}

                  {/* ================> END Task Management <============== */}

                  {/* =================> START Timesheet <============= */}
                  {permissions &&
                  (permissions["View Timesheet Entry"] ||
                    permissions["View Timesheet History"] ||
                    permissions["View Timesheet Approve"]) ? (
                    <div className="with-subMenu">
                      <Accordion.Item eventKey="7">
                        <Accordion.Header className="menus-items ">
                          <Link
                            to="#"
                            className="icon-menu-name-arrow"
                            onClick={() => {
                              handleTimesheet();
                              setWalletCheck(false);
                              setApproveLeave(false);
                              setLeave(false);
                              setDashboard(false);
                              setAttendance(false);
                              setTaskManagement(false);
                              setNotifications(false);
                              setCommunication(false);
                              setProjectMan(false);
                            }}
                          >
                            <div className="menu-left">
                              <div className="icon-left">
                                <Link to="/timesheet">
                                  {currentRoute === "/timesheet" ||
                                  currentRoute === "/timesheethistory" ||
                                  currentRoute === "/TimesheetApproval" ||
                                  clickMenu === "TManagments" ? (
                                    <img src={timesheet_icon_active} alt="" />
                                  ) : (
                                    <img src={timesheet_icon} alt="" />
                                  )}
                                </Link>
                              </div>
                              <div
                                className={
                                  clickMenu === "TManagments" ||
                                  currentRoute === "/timesheet" ||
                                  currentRoute === "/timesheethistory" ||
                                  currentRoute === "/TimesheetApproval"
                                    ? // currentRoute === "/EmployeeTimesheetDetails"
                                      "menu-name activeColorBlue"
                                    : "menu-name"
                                }
                              >
                                Timesheet Management
                              </div>
                            </div>

                            <div className="arrow">
                              {Timesheet ? (
                                <img src={down_arrow} alt="" />
                              ) : (
                                <img src={ic_blueUP_arrow} alt="" />
                              )}
                            </div>
                          </Link>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className={sidebar ? "sub-menus" : "none"}>
                            <Link
                              to="/timesheet"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                                setClickMenu("");
                              }}
                            >
                              {permissions &&
                                permissions["View Timesheet Entry"] && (
                                  <div
                                    className={
                                      currentRoute === "/timesheet"
                                        ? "menu-name activeColorBlue"
                                        : "menu-name"
                                    }
                                  >
                                    Timesheet Entry
                                  </div>
                                )}
                            </Link>

                            <Link
                              to="/timesheethistory"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                                setClickMenu("");
                              }}
                            >
                              {permissions &&
                                permissions["View Timesheet History"] && (
                                  <div
                                    className={
                                      currentRoute === "/timesheethistory"
                                        ? "menu-name activeColorBlue"
                                        : "menu-name"
                                    }
                                  >
                                    Timesheet History
                                  </div>
                                )}
                            </Link>
                            <Link
                              to="/TimesheetApproval"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                                setClickMenu("");
                              }}
                            >
                              {permissions &&
                                permissions["View Timesheet Approve"] && (
                                  <div
                                    className={
                                      currentRoute === "/TimesheetApproval"
                                        ? "menu-name activeColorBlue"
                                        : "menu-name"
                                    }
                                  >
                                    Timesheet Approval
                                  </div>
                                )}
                            </Link>
                            <Link
                              to="/EmployeeTimesheetDetails"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                                setClickMenu("TManagments");
                              }}
                            >
                              {permissions &&
                                permissions[
                                  "View Employee Timesheet Details"
                                ] && (
                                  <div
                                    className={
                                      clickMenu === "TManagments"
                                        ? "menu-name activeColorBlue"
                                        : "menu-name"
                                    }
                                  >
                                    Employee Timesheet Details
                                  </div>
                                )}
                            </Link>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </div>
                  ) : null}

                  {/* ================> End Timesheet <============== */}

                  {/* =================> START ProjectMangent <============= */}
                  {
                    (permissions && permissions["View Project Documents"]) ||
                    permissions["View Project Resources"] ||
                    permissions["View Project Issues"] ||
                    permissions["View Project Allocation"] ||
                    permissions["View Project Timesheet Details"] ||
                    permissions["View Employee Timesheet Detail"] ||
                    permissions["View Project"] ||
                    permissions["View Project Schedule"] ? (
                      <div className="with-subMenu">
                        <Accordion.Item eventKey="8">
                          <Accordion.Header className="menus-items ">
                            <Link
                              to="#"
                              className="icon-menu-name-arrow"
                              onClick={() => {
                                handleProjectMan();
                                setTimesheet(false);
                                setWalletCheck(false);
                                setApproveLeave(false);
                                setLeave(false);
                                setDashboard(false);
                                setAttendance(false);
                                setTaskManagement(false);
                                setNotifications(false);
                                setCommunication(false);
                              }}
                            >
                              <div className="menu-left">
                                <div className="icon-left">
                                  <Link to="/projectdocumentation">
                                    {currentRoute === "/projectdocumentation" ||
                                    currentRoute === "/projectdetails" ||
                                    currentRoute === "/ProjectSchedule" ||
                                    currentRoute === "/projectresources" ||
                                    currentRoute === "/Project" ||
                                    currentRoute === "/ProjectAssignment" ||
                                    clickMenu === "PManagments" ||
                                    currentRoute ===
                                      "/ProjectTimesheetDetails" ? (
                                      <img
                                        src={ProjectMan_Dark}
                                        alt=""
                                        className="mt-1"
                                      />
                                    ) : (
                                      <img
                                        src={ProjectMan_active}
                                        alt=""
                                        className="mt-1"
                                      />
                                    )}
                                  </Link>
                                </div>
                                <div
                                  className={
                                    clickMenu === "PManagments" ||
                                    currentRoute === "/projectdocumentation" ||
                                    currentRoute === "/projectdetails" ||
                                    currentRoute === "/ProjectSchedule" ||
                                    currentRoute === "/projectresources" ||
                                    currentRoute === "/Project" ||
                                    currentRoute === "/ProjectAssignment" ||
                                    // currentRoute ===
                                    //   "/EmployeeTimesheetDetails" ||
                                    currentRoute === "/ProjectTimesheetDetails"
                                      ? "menu-name activeColorBlue"
                                      : "menu-name"
                                  }
                                >
                                  Project Management
                                </div>
                              </div>

                              <div className="arrow">
                                {ProjectMan ? (
                                  <img src={down_arrow} alt="" />
                                ) : (
                                  <img src={ic_blueUP_arrow} alt="" />
                                )}
                              </div>
                            </Link>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className={sidebar ? "sub-menus" : "none"}>
                              <Link
                                to="/projectdocumentation"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                  setClickMenu("");
                                }}
                              >
                                {permissions &&
                                  permissions["View Project Documents"] && (
                                    <div
                                      className={
                                        currentRoute ===
                                          "/projectdocumentation" ||
                                        currentRoute === "/projectdetails"
                                          ? "menu-name activeColorBlue"
                                          : "menu-name"
                                      }
                                    >
                                      Project Documents
                                    </div>
                                  )}
                              </Link>
                              <Link
                                to="/ProjectSchedule"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                  setClickMenu("");
                                }}
                              >
                                {permissions &&
                                  permissions["View Project Schedule"] && (
                                    <div
                                      className={
                                        currentRoute === "/ProjectSchedule"
                                          ? "menu-name activeColorBlue"
                                          : "menu-name"
                                      }
                                    >
                                      Project Schedule
                                    </div>
                                  )}
                              </Link>
                              {permissions &&
                                permissions["View Project Resources"] && (
                                  <Link
                                    to="/projectresources"
                                    className="sub-menue-items"
                                    onClick={() => {
                                      isMobile
                                        ? setSidebar(false)
                                        : setSidebar(true);
                                      setClickMenu("");
                                    }}
                                  >
                                    {/* {permissions && permissions["Project Resources"] && ( */}
                                    <div
                                      className={
                                        currentRoute === "/projectresources"
                                          ? "menu-name activeColorBlue"
                                          : "menu-name"
                                      }
                                    >
                                      Project Resources
                                    </div>
                                    {/* )} */}
                                  </Link>
                                )}
                              {permissions &&
                                permissions["View Project Issues"] && (
                                  <Link
                                    to="#"
                                    className="sub-menue-items"
                                    onClick={() => {
                                      isMobile
                                        ? setSidebar(false)
                                        : setSidebar(true);
                                      setClickMenu("");
                                    }}
                                  >
                                    <div
                                      className={
                                        currentRoute === "/"
                                          ? "menu-name activeColorBlue"
                                          : "menu-name"
                                      }
                                    >
                                      Project Issues
                                    </div>
                                  </Link>
                                )}
                              <Link
                                to="/Project"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                  setClickMenu("");
                                }}
                              >
                                {permissions && permissions["View Project"] && (
                                  <div
                                    className={
                                      currentRoute === "/Project"
                                        ? "menu-name activeColorBlue"
                                        : "menu-name"
                                    }
                                  >
                                    Projects
                                  </div>
                                )}
                              </Link>
                              <Link
                                to="/ProjectAssignment"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                  setClickMenu("");
                                }}
                              >
                                {permissions &&
                                  permissions["View Project Allocation"] && (
                                    <div
                                      className={
                                        currentRoute === "/ProjectAssignment"
                                          ? "menu-name activeColorBlue"
                                          : "menu-name"
                                      }
                                    >
                                      Project Allocation
                                    </div>
                                  )}
                              </Link>
                              <Link
                                to="/EmployeeTimesheetDetails"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                  setClickMenu("PManagments");
                                }}
                              >
                                {permissions &&
                                  permissions[
                                    "View Employee Timesheet Detail"
                                  ] && (
                                    <div
                                      className={
                                        clickMenu === "PManagments"
                                          ? "menu-name activeColorBlue"
                                          : "menu-name"
                                      }
                                    >
                                      Employee Timesheet Details
                                    </div>
                                  )}
                              </Link>
                              <Link
                                to="/ProjectTimesheetDetails"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                  setClickMenu("");
                                }}
                              >
                                {permissions &&
                                  permissions[
                                    "View Project Timesheet Details"
                                  ] && (
                                    <div
                                      className={
                                        currentRoute ===
                                        "/ProjectTimesheetDetails"
                                          ? "menu-name activeColorBlue"
                                          : "menu-name"
                                      }
                                    >
                                      Project Timesheet Details
                                    </div>
                                  )}
                              </Link>
                              {/* <Link
                  to="/MyDiary"
                  className="sub-menue-items"
                  onClick={() => {
                    isMobile ? setSidebar(false) : setSidebar(true);
                  }}
                > 
                {
                 permissions &&  permissions["My Diary"] && (
                    <div
                    className={
                      currentRoute === "/MyDiary"
                        ? "menu-name activeColorBlue"
                        : "menu-name"
                    }
                  >
                    My Diary
                  </div>
                  )
                }
                </Link> */}
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </div>
                    ) : null
                    // ) : null
                  }

                  {/* ================> End Timesheet <============== */}

                  {/* =================> START Organizatoin <============= */}

                  {permissions &&
                  (permissions["Team Chart"] ||
                    permissions["About Organization"] ||
                    permissions["Privacy Policy"] ||
                    permissions["Holiday And Events"] ||
                    permissions["Terms & Conditions"] ||
                    permissions["View Holiday"] ||
                    permissions["View All Announcement"]) ? (
                    <div className="with-subMenu">
                      <Accordion.Item eventKey="9">
                        <Accordion.Header className="menus-items ">
                          <Link
                            to="#"
                            className="icon-menu-name-arrow"
                            onClick={() => {
                              handleNotifications();
                              setAttendance(false);
                              setTimesheet(false);
                              setWalletCheck(false);
                              setTaskManagement(false);
                              setCommunication(false);
                              setProjectMan(false);
                            }}
                          >
                            <div className="menu-left">
                              <div className="icon-left">
                                <Link to="/teamchart">
                                  {currentRoute === "/teamchart" ||
                                  currentRoute === "/AllAnnouncement" ||
                                  currentRoute === "/MyAnnouncement" ||
                                  currentRoute === "/holidaysandevents" ||
                                  currentRoute === "/TearmsAmdCondiation" ||
                                  currentRoute === "/PrivacyPolicy" ||
                                  currentRoute === "/AboutOrganziation" ||
                                  currentRoute === "/Holidays" ||
                                  currentRoute === "/Academic" ||
                                  currentRoute === "/CalendarSettings" ||
                                  currentRoute === "/ShiftMaster" ||
                                  currentRoute === "/OrgainsationInfo" ? (
                                    <img
                                      src={Organ_Dark}
                                      alt=""
                                      className="mt-1"
                                    />
                                  ) : (
                                    <img
                                      src={Organ_active}
                                      alt=""
                                      className="mt-1"
                                    />
                                  )}
                                </Link>
                              </div>
                              <div
                                className={
                                  currentRoute === "/teamchart" ||
                                  currentRoute === "/AllAnnouncement" ||
                                  currentRoute === "/MyAnnouncement" ||
                                  currentRoute === "/TearmsAmdCondiation" ||
                                  currentRoute === "/PrivacyPolicy" ||
                                  currentRoute === "/AboutOrganziation" ||
                                  currentRoute === "/holidaysandevents" ||
                                  currentRoute === "/Holidays" ||
                                  currentRoute === "/Academic" ||
                                  currentRoute === "/CalendarSettings" ||
                                  currentRoute === "/OrgainsationInfo" ||
                                  currentRoute === "/ShiftMaster"
                                    ? "menu-name activeColorBlue"
                                    : "menu-name"
                                }
                              >
                                Organization
                              </div>
                            </div>

                            <div className="arrow">
                              {Notifications ? (
                                <img src={down_arrow} alt="" />
                              ) : (
                                <img src={ic_blueUP_arrow} alt="" />
                              )}
                            </div>
                          </Link>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className={sidebar ? "sub-menus" : "none"}>
                            <Link
                              to="/teamchart"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              {permissions && permissions["Team Chart"] && (
                                <div
                                  className={
                                    currentRoute === "/teamchart"
                                      ? "menu-name activeColorBlue"
                                      : "menu-name"
                                  }
                                >
                                  Team Chart
                                </div>
                              )}
                            </Link>
                            <Link
                              to="/AllAnnouncement"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              {permissions &&
                                permissions["View All Announcement"] && (
                                  <div
                                    className={
                                      currentRoute === "/AllAnnouncement" ||
                                      currentRoute === "/MyAnnouncement"
                                        ? "menu-name activeColorBlue"
                                        : "menu-name"
                                    }
                                  >
                                    Announcements
                                  </div>
                                )}
                            </Link>
                            {permissions && permissions["View Holiday"] && (
                              <Link
                                to="/Holidays"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {/* {permissions && permissions["Team Chart"] && ( */}
                                <div
                                  className={
                                    currentRoute === "/Holidays"
                                      ? "menu-name activeColorBlue"
                                      : "menu-name"
                                  }
                                >
                                  Holidays
                                </div>
                                {/* )} */}
                              </Link>
                            )}
                            <Link
                              to="/Academic"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              {/* {permissions && permissions["Team Chart"] && ( */}
                              <div
                                className={
                                  currentRoute === "/Academic"
                                    ? "menu-name activeColorBlue"
                                    : "menu-name"
                                }
                              >
                                Financial Year
                              </div>
                              {/* )} */}
                            </Link>
                            <Link
                              to="/CalendarSettings"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              {/* {permissions && permissions["Team Chart"] && ( */}
                              <div
                                className={
                                  currentRoute === "/CalendarSettings"
                                    ? "menu-name activeColorBlue"
                                    : "menu-name"
                                }
                              >
                                Calendar Settings
                              </div>
                              {/* )} */}
                            </Link>
                            <Link
                              to="/holidaysandevents"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              {permissions &&
                                permissions["Holiday And Events"] && (
                                  <div
                                    className={
                                      currentRoute === "/holidaysandevents"
                                        ? "menu-name activeColorBlue"
                                        : "menu-name"
                                    }
                                  >
                                    Holidays And Events
                                  </div>
                                )}
                            </Link>
                            <Link
                              to="/TearmsAmdCondiation"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              {permissions &&
                                permissions["Terms & Conditions"] && (
                                  <div
                                    className={
                                      currentRoute === "/TearmsAmdCondiation"
                                        ? "menu-name activeColorBlue"
                                        : "menu-name"
                                    }
                                  >
                                    Terms & Conditions
                                  </div>
                                )}
                            </Link>
                            <Link
                              to="/PrivacyPolicy"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              {permissions && permissions["Privacy Policy"] && (
                                <div
                                  className={
                                    currentRoute === "/PrivacyPolicy"
                                      ? "menu-name activeColorBlue"
                                      : "menu-name"
                                  }
                                >
                                  Privacy Policy
                                </div>
                              )}
                            </Link>
                            <Link
                              to="/AboutOrganziation"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              {permissions &&
                                permissions["About Organization"] && (
                                  <div
                                    className={
                                      currentRoute === "/AboutOrganziation" ||
                                      currentRoute === "/AboutOrganziation"
                                        ? "menu-name activeColorBlue"
                                        : "menu-name"
                                    }
                                  >
                                    About Organization
                                  </div>
                                )}
                            </Link>
                            <Link
                              to="/ShiftMaster"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              <div
                                className={
                                  currentRoute === "/ShiftMaster" ||
                                  currentRoute === "/ShiftMaster"
                                    ? "menu-name activeColorBlue"
                                    : "menu-name"
                                }
                              >
                                Shift Master
                              </div>
                            </Link>
                            <Link
                              to="/OrgainsationInfo"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              <div
                                className={
                                  currentRoute === "/OrgainsationInfo" ||
                                  currentRoute === "/OrgainsationInfo"
                                    ? "menu-name activeColorBlue"
                                    : "menu-name"
                                }
                              >
                                Org Info
                              </div>
                            </Link>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </div>
                  ) : null}
                  {/* =================> End Organixatoin <============= */}

                  {/* =================> START Complaince <============= */}

                  {permissions &&
                    (permissions["View Team Not Assigned"] ||
                      permissions["View Project Assigned"] ||
                      permissions["View Project De-Assigned"] ||
                      permissions["View Timesheet Not Entered"] ||
                      permissions["View Project Not Assigned"] ||
                      permissions["View Two Plus Project Assigned"] ||
                      permissions["View Task Not Added"] ||
                      permissions["View Timesheet Not Approved"] ||
                      permissions["View Timesheet Period Over"] ||
                      permissions["View Bench List"] ||
                      permissions["View Compliance Settings"] ||
                      permissions["View User Not Login"] ||
                      permissions["View User Last Login"] ||
                      permissions["View User Activity"]) && (
                      <div className="with-subMenu">
                        <Accordion.Item eventKey="10">
                          <Accordion.Header className="menus-items ">
                            <Link
                              to="#"
                              className="icon-menu-name-arrow"
                              onClick={() => {
                                handleNotifications();
                                setAttendance(false);
                                setWalletCheck(false);
                                setTimesheet(false);
                                setTaskManagement(false);
                                setCommunication(false);
                                setProjectMan(false);
                              }}
                            >
                              <div className="menu-left">
                                <div className="icon-left">
                                  <Link to="/teamchart">
                                    {currentRoute === "/TeamNotAssigned" ||
                                    currentRoute === "/ProjectNotAssigned" ||
                                    currentRoute === "/ProjectAssigned" ||
                                    currentRoute === "/ProjectDeAssign" ||
                                    currentRoute ===
                                      "/TwoPlusProjectsAssigned" ||
                                    currentRoute ===
                                      "/TaskNotAddedAgainstProject" ||
                                    currentRoute === "/TaskNotApproved" ||
                                    currentRoute === "/TimesheetNotEntered" ||
                                    currentRoute === "/TimesheetNotApproved" ||
                                    currentRoute === "/TimePeriodOver" ||
                                    currentRoute === "/BenchList" ||
                                    currentRoute === "/UsernotLogin" ||
                                    currentRoute === "/ComplianceSetting" ||
                                    currentRoute === "/ComplianceSetting" ||
                                    currentRoute === "/UserLastLogin" ||
                                    currentRoute === "/UserActivity" ||
                                    currentRoute === "/UserNotLogin" ? (
                                      <img
                                        src={ComplianceActive}
                                        alt=""
                                        className="mt-1"
                                      />
                                    ) : (
                                      <img
                                        src={Compliance}
                                        alt=""
                                        className="mt-1"
                                      />
                                    )}
                                  </Link>
                                </div>
                                <div
                                  className={
                                    currentRoute === "/TeamNotAssigned" ||
                                    currentRoute === "/ProjectNotAssigned" ||
                                    currentRoute === "/ProjectAssigned" ||
                                    currentRoute === "/ProjectDeAssign" ||
                                    currentRoute ===
                                      "/TwoPlusProjectsAssigned" ||
                                    currentRoute ===
                                      "/TaskNotAddedAgainstProject" ||
                                    currentRoute === "/TaskNotApproved" ||
                                    currentRoute === "/TimesheetNotEntered" ||
                                    currentRoute === "/TimesheetNotApproved" ||
                                    currentRoute === "/TimePeriodOver" ||
                                    currentRoute === "/BenchList" ||
                                    currentRoute === "/UsernotLogin" ||
                                    currentRoute === "/ComplianceSetting" ||
                                    currentRoute === "/UserNotLogin" ||
                                    currentRoute === "/UserLastLogin" ||
                                    currentRoute === "/UserActivity"
                                      ? "menu-name activeColorBlue"
                                      : "menu-name"
                                  }
                                >
                                  Compliance
                                </div>
                              </div>

                              <div className="arrow">
                                {Notifications ? (
                                  <img src={down_arrow} alt="" />
                                ) : (
                                  <img src={ic_blueUP_arrow} alt="" />
                                )}
                              </div>
                            </Link>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className={sidebar ? "sub-menus" : "none"}>
                              <Link
                                to="/TeamNotAssigned"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {/* {permissions && permissions["Announcements"] && ( */}
                                {permissions &&
                                  permissions["View Team Not Assigned"] && (
                                    <div
                                      className={
                                        currentRoute === "/TeamNotAssigned"
                                          ? "menu-name activeColorBlue"
                                          : "menu-name"
                                      }
                                    >
                                      Team Not Assigned
                                    </div>
                                  )}
                              </Link>
                              <Link
                                to="/ProjectNotAssigned"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                  permissions["View Project Not Assigned"] && (
                                    <div
                                      className={
                                        currentRoute === "/ProjectNotAssigned"
                                          ? "menu-name activeColorBlue"
                                          : "menu-name"
                                      }
                                    >
                                      Project Not Assigned
                                    </div>
                                  )}
                              </Link>
                              <Link
                                to="/ProjectAssigned"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                  permissions["View Project Assigned"] && (
                                    <div
                                      className={
                                        currentRoute === "/ProjectAssigned"
                                          ? "menu-name activeColorBlue"
                                          : "menu-name"
                                      }
                                    >
                                      Project Assigned
                                    </div>
                                  )}
                              </Link>
                              <Link
                                to="/ProjectDeAssign"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                  permissions["View Project De-Assigned"] && (
                                    <div
                                      className={
                                        currentRoute === "/ProjectDeAssign"
                                          ? "menu-name activeColorBlue"
                                          : "menu-name"
                                      }
                                    >
                                      Project De-Assigned
                                    </div>
                                  )}
                              </Link>

                              <Link
                                to="/TwoPlusProjectsAssigned"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                  permissions[
                                    "View Two Plus Project Assigned"
                                  ] && (
                                    <div
                                      className={
                                        currentRoute ===
                                        "/TwoPlusProjectsAssigned"
                                          ? "menu-name activeColorBlue"
                                          : "menu-name"
                                      }
                                    >
                                      2+ Projects Assigned
                                    </div>
                                  )}
                              </Link>
                              <Link
                                to="/TaskNotAddedAgainstProject"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                  permissions["View Task Not Added"] && (
                                    <div
                                      className={
                                        currentRoute ===
                                        "/TaskNotAddedAgainstProject"
                                          ? "menu-name activeColorBlue"
                                          : "menu-name"
                                      }
                                    >
                                      Task Not Added
                                    </div>
                                  )}
                              </Link>
                              <Link
                                to="/TaskNotApproved"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                  permissions["View Task Not Approved"] && (
                                    <div
                                      className={
                                        currentRoute === "/TaskNotApproved"
                                          ? "menu-name activeColorBlue"
                                          : "menu-name"
                                      }
                                    >
                                      Task Not Approved
                                    </div>
                                  )}
                              </Link>
                              <Link
                                to="/TimesheetNotEntered"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                  permissions["View Timesheet Not Entered"] && (
                                    <div
                                      className={
                                        currentRoute === "/TimesheetNotEntered"
                                          ? "menu-name activeColorBlue"
                                          : "menu-name"
                                      }
                                    >
                                      Timesheet Not Entered
                                    </div>
                                  )}
                              </Link>
                              <Link
                                to="/TimesheetNotApproved"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                  permissions[
                                    "View Timesheet Not Approved"
                                  ] && (
                                    <div
                                      className={
                                        currentRoute === "/TimesheetNotApproved"
                                          ? "menu-name activeColorBlue"
                                          : "menu-name"
                                      }
                                    >
                                      Timesheet Not Approved
                                    </div>
                                  )}
                              </Link>
                              <Link
                                to="/TimePeriodOver"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                  permissions["View Timesheet Period Over"] && (
                                    <div
                                      className={
                                        currentRoute === "/TimePeriodOver"
                                          ? "menu-name activeColorBlue"
                                          : "menu-name"
                                      }
                                    >
                                      Timesheet Period Over
                                    </div>
                                  )}
                              </Link>
                              <Link
                                to="/BenchList"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                  permissions["View Bench List"] && (
                                    <div
                                      className={
                                        currentRoute === "/BenchList"
                                          ? "menu-name activeColorBlue"
                                          : "menu-name"
                                      }
                                    >
                                      Bench list
                                    </div>
                                  )}
                              </Link>
                              <Link
                                to="/ComplianceSetting"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                  permissions["View Compliance Settings"] && (
                                    <div
                                      className={
                                        currentRoute === "/ComplianceSetting"
                                          ? "menu-name activeColorBlue"
                                          : "menu-name"
                                      }
                                    >
                                      Compliance Setting
                                    </div>
                                  )}
                              </Link>

                              <Link
                                to="/UserNotLogin"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {/* {permissions && permissions["Announcements"] && ( */}
                                {permissions &&
                                  permissions["View User Not Login"] && (
                                    <div
                                      className={
                                        currentRoute === "/UserNotLogin"
                                          ? "menu-name activeColorBlue"
                                          : "menu-name"
                                      }
                                    >
                                      User Not Login
                                    </div>
                                  )}
                              </Link>
                              <Link
                                to="/UserLastLogin"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {/* {permissions && permissions["Announcements"] && ( */}
                                {permissions &&
                                  permissions["View User Last Login"] && (
                                    <div
                                      className={
                                        currentRoute === "/UserLastLogin"
                                          ? "menu-name activeColorBlue"
                                          : "menu-name"
                                      }
                                    >
                                      User Last Login
                                    </div>
                                  )}
                              </Link>

                              <Link
                                to="/UserActivity"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {/* {permissions && permissions["Announcements"] && ( */}
                                {permissions &&
                                  permissions["View User Activity"] && (
                                    <div
                                      className={
                                        currentRoute === "/UserActivity"
                                          ? "menu-name activeColorBlue"
                                          : "menu-name"
                                      }
                                    >
                                      User Activity
                                    </div>
                                  )}
                              </Link>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </div>
                    )}

                  {/* ) : null
              } */}

                  {/* ================> END Compliance <============== */}

                  {/* =================> START Teams Preference <============= */}

                  {permissions && permissions["View Create Team"] && (
                    <div className="with-subMenu">
                      <Accordion.Item eventKey="11">
                        <Accordion.Header className="menus-items ">
                          <Link
                            to="#"
                            className="icon-menu-name-arrow"
                            onClick={() => {
                              handleNotifications();
                              setWalletCheck(false);
                              setAttendance(false);
                              setTimesheet(false);
                              setTaskManagement(false);
                              setCommunication(false);
                              setProjectMan(false);
                            }}
                          >
                            <div className="menu-left">
                              <div className="icon-left">
                                <Link to="/CreateTeam">
                                  {currentRoute === "/CreateTeam" ||
                                  currentRoute === "/ManageTeam" ? (
                                    <img
                                      src={TeamsManagementActiveIcon}
                                      alt=""
                                      className="mt-1"
                                    />
                                  ) : (
                                    <img
                                      src={TeamsManagementIcon}
                                      alt=""
                                      className="mt-1"
                                    />
                                  )}
                                </Link>
                              </div>
                              <div
                                className={
                                  currentRoute === "/CreateTeam" ||
                                  currentRoute === "/ManageTeam"
                                    ? "menu-name activeColorBlue"
                                    : "menu-name"
                                }
                              >
                                Teams Management
                              </div>
                            </div>

                            <div className="arrow">
                              {Notifications ? (
                                <img src={down_arrow} alt="" />
                              ) : (
                                <img src={ic_blueUP_arrow} alt="" />
                              )}
                            </div>
                          </Link>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className={sidebar ? "sub-menus" : "none"}>
                            <Link
                              to="/CreateTeam"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              {permissions &&
                                permissions["View Create Team"] && (
                                  <div
                                    className={
                                      currentRoute === "/CreateTeam"
                                        ? "menu-name activeColorBlue"
                                        : "menu-name"
                                    }
                                  >
                                    Create Team
                                  </div>
                                )}
                            </Link>
                            {permissions && permissions["View Manage Team"] && (
                              <Link
                                to="/ManageTeam"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {/* {permissions && permissions["Announcements"] && ( */}
                                <div
                                  className={
                                    currentRoute === "/ManageTeam"
                                      ? "menu-name activeColorBlue"
                                      : "menu-name"
                                  }
                                >
                                  Manage Teams
                                </div>
                                {/* )} */}
                              </Link>
                            )}
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </div>
                  )}

                  {/* =================> START Project Costing <============= */}
                  {permissions &&
                    (permissions["View Project Cost Report"] ||
                      permissions["View Salary Structure"] ||
                      permissions["View Generate Salary Slip"]) && (
                      <div className="with-subMenu">
                        <Accordion.Item eventKey="12">
                          <Accordion.Header className="menus-items ">
                            <Link
                              to="#"
                              className="icon-menu-name-arrow"
                              onClick={() => {
                                handleNotifications();
                                setAttendance(false);
                                setWalletCheck(false);
                                setTimesheet(false);
                                setTaskManagement(false);
                                setCommunication(false);
                                setProjectMan(false);
                              }}
                            >
                              <div className="menu-left">
                                <div className="icon-left">
                                  <Link to="#">
                                    {currentRoute === "/ProjectCost" ||
                                    currentRoute === "/ProjectCostReport" ||
                                    currentRoute === "/SalaryStructure" ||
                                    currentRoute === "/GenerateSalarySlip" ||
                                    currentRoute === "/ProjectCostEnd" ? (
                                      <img
                                        src={projCostActive}
                                        alt=""
                                        className="mt-1"
                                      />
                                    ) : (
                                      <img
                                        src={projCost}
                                        alt=""
                                        className="mt-1"
                                      />
                                    )}
                                  </Link>
                                </div>
                                <div
                                  className={
                                    currentRoute === "/ProjectCost" ||
                                    currentRoute === "/ProjectCostReport" ||
                                    currentRoute === "/SalaryStructure" ||
                                    currentRoute === "/GenerateSalarySlip" ||
                                    currentRoute === "/ProjectCostEnd"
                                      ? "menu-name activeColorBlue"
                                      : "menu-name"
                                  }
                                >
                                  Project Costing
                                </div>
                              </div>

                              <div className="arrow">
                                {Notifications ? (
                                  <img src={down_arrow} alt="" />
                                ) : (
                                  <img src={ic_blueUP_arrow} alt="" />
                                )}
                              </div>
                            </Link>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className={sidebar ? "sub-menus" : "none"}>
                              <Link
                                to="/ProjectCost"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions && permissions["Project Cost"] && (
                                  <div
                                    className={
                                      currentRoute === "/ProjectCost"
                                        ? "menu-name activeColorBlue"
                                        : "menu-name"
                                    }
                                  >
                                    Project Cost
                                  </div>
                                )}
                              </Link>
                              <Link
                                to="/ProjectCostReport"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                  permissions["View Project Cost Report"] && (
                                    <div
                                      className={
                                        currentRoute === "/ProjectCostReport"
                                          ? "menu-name activeColorBlue"
                                          : "menu-name"
                                      }
                                    >
                                      Project Cost Report
                                    </div>
                                  )}
                              </Link>
                              <Link
                                to="/SalaryStructure"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                  permissions["View Salary Structure"] && (
                                    <div
                                      className={
                                        currentRoute === "/SalaryStructure"
                                          ? "menu-name activeColorBlue"
                                          : "menu-name"
                                      }
                                    >
                                      Salary Structure
                                    </div>
                                  )}
                              </Link>
                              <Link
                                to="/GenerateSalarySlip"
                                className="sub-menue-items"
                                onClick={() => {
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                {permissions &&
                                  permissions["View Generate Salary Slip"] && (
                                    <div
                                      className={
                                        currentRoute === "/GenerateSalarySlip"
                                          ? "menu-name activeColorBlue"
                                          : "menu-name"
                                      }
                                    >
                                      Generate Salary Slip
                                    </div>
                                  )}
                              </Link>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </div>
                    )}

                  {/* =================> START Employee <============= */}
                  {permissions && permissions["Create Employee"] && (
                    <div className="with-subMenu">
                      <Accordion.Item eventKey="13">
                        <Accordion.Header className="menus-items ">
                          <Link
                            to="#"
                            className="icon-menu-name-arrow"
                            onClick={() => {
                              handleNotifications();
                              setAttendance(false);
                              setWalletCheck(false);
                              setTimesheet(false);
                              setTaskManagement(false);
                              setCommunication(false);
                              setProjectMan(false);
                            }}
                          >
                            <div className="menu-left">
                              <div className="icon-left">
                                <Link to="#">
                                  {currentRoute === "/List" ||
                                  currentRoute === "/AddEmployee" ||
                                  currentRoute ===
                                    "/EditEmployeeProfile/:id" ? (
                                    <img
                                      src={EmployeeManagementActiveIcon}
                                      alt=""
                                      className="mt-1"
                                    />
                                  ) : (
                                    <img
                                      src={EmployeeManagementIcon}
                                      alt=""
                                      className="mt-1"
                                    />
                                  )}
                                </Link>
                              </div>
                              <div
                                className={
                                  currentRoute === "/List" ||
                                  currentRoute === "/AddEmployee" ||
                                  currentRoute === "/EditEmployeeProfile/:id"
                                    ? "menu-name activeColorBlue"
                                    : "menu-name"
                                }
                              >
                                Employee Management
                              </div>
                            </div>

                            <div className="arrow">
                              {Notifications ? (
                                <img src={down_arrow} alt="" />
                              ) : (
                                <img src={ic_blueUP_arrow} alt="" />
                              )}
                            </div>
                          </Link>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className={sidebar ? "sub-menus" : "none"}>
                            <Link
                              to="/List"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              {permissions && permissions["Employee List"] && (
                                <div
                                  className={
                                    currentRoute === "/List"
                                      ? "menu-name activeColorBlue"
                                      : "menu-name"
                                  }
                                >
                                  Employee List
                                </div>
                              )}
                            </Link>
                            <Link
                              to="/AddEmployee"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              {permissions && permissions["Add Employee"] && (
                                <div
                                  className={
                                    currentRoute === "/AddEmployee"
                                      ? "menu-name activeColorBlue"
                                      : "menu-name"
                                  }
                                >
                                  Add Employee
                                </div>
                              )}
                            </Link>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </div>
                  )}

                  {/* =================> START General setting <============= */}
                  <div className="with-subMenu">
                    {permissions && permissions["View Settings"] && (
                      <Accordion.Item eventKey="14">
                        <Accordion.Header className="menus-items">
                          <Link
                            to="#"
                            className="icon-menu-name-arrow"
                            onClick={() => {
                              handleNotifications();
                              setWalletCheck(false);
                              setAttendance(false);
                              setTimesheet(false);
                              setTaskManagement(false);
                              setCommunication(false);
                              setProjectMan(false);
                            }}
                          >
                            <div className="menu-left">
                              <div className="icon-left">
                                <Link to="#">
                                  {currentRoute === "/GeneralSetting" ||
                                  currentRoute === "/EmployeeCode" ? (
                                    <img
                                      src={GeneralSettingActiveIcon}
                                      alt=""
                                      className="mt-1"
                                    />
                                  ) : (
                                    <img
                                      src={GeneralSettingIcon}
                                      alt=""
                                      className="mt-1"
                                    />
                                  )}
                                </Link>
                              </div>
                              <div
                                className={
                                  currentRoute === "/GeneralSetting" ||
                                  currentRoute === "/EmployeeCode"
                                    ? "menu-name activeColorBlue"
                                    : "menu-name"
                                }
                              >
                                General Setting
                              </div>
                            </div>

                            <div className="arrow">
                              {Notifications ? (
                                <img src={down_arrow} alt="" />
                              ) : (
                                <img src={ic_blueUP_arrow} alt="" />
                              )}
                            </div>
                          </Link>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className={sidebar ? "sub-menus" : "none"}>
                            <Link
                              to="/GeneralSetting"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              {/* {permissions && permissions["Announcements"] && ( */}
                              <div
                                className={
                                  currentRoute === "/GeneralSetting"
                                    ? "menu-name activeColorBlue"
                                    : "menu-name"
                                }
                              >
                                Settings
                              </div>
                              {/* )} */}
                            </Link>
                            <Link
                              to="/EmployeeCode"
                              className="sub-menue-items"
                              onClick={() => {
                                isMobile ? setSidebar(false) : setSidebar(true);
                              }}
                            >
                              {permissions && permissions["EmployeeCode"] && (
                                <div
                                  className={
                                    currentRoute === "/EmployeeCode"
                                      ? "menu-name activeColorBlue"
                                      : "menu-name"
                                  }
                                >
                                  Employee Code
                                </div>
                              )}
                            </Link>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    )}
                  </div>
                </>
              ) : null}

              {permissions["Products"] ||
              permissions["Categories"] ||
              permissions["Employee Wallet"] ||
              permissions["Store"] ||
              permissions["Wallet Recharges"] ||
              permissions["Wallet Sales"] ? (
                <div className="with-subMenu">
                  <Accordion.Item eventKey="15">
                    <Accordion.Header className="menus-items ">
                      <Link
                        to="#"
                        className="icon-menu-name-arrow"
                        onClick={() => {
                          handleWallet();
                          setNotifications(false);
                          setAttendance(false);
                          setTimesheet(false);
                          setTaskManagement(false);
                          setCommunication(false);
                          setProjectMan(false);
                        }}
                      >
                        <div className="menu-left">
                          <div className="icon-left">
                            <Link to="#">
                              {currentRoute === "/EmpoloyeeWallet" ||
                              currentRoute === "/RechargeWallet" ||
                              currentRoute === "/WalletSales" ||
                              currentRoute === "/TransactionReceipt" ||
                              currentRoute === "/WalletRecharge" ||
                              currentRoute === "/Store" ||
                              currentRoute === "/Categories" ||
                              currentRoute === "/Product" ||
                              currentRoute === "/CreateCategories" ||
                              currentRoute === "/CreateProducts" ||
                              currentRoute === "/ProductStock" ||
                              currentRoute === "/AddProductStock" ||
                              currentRoute === "/IssueCard" ? (
                                <img
                                  src={walletActive}
                                  alt=""
                                  className="mt-1"
                                />
                              ) : (
                                <img src={wallet} alt="" className="mt-1" />
                              )}
                            </Link>
                          </div>
                          <div
                            className={
                              currentRoute === "/EmpoloyeeWallet" ||
                              currentRoute === "/RechargeWallet" ||
                              currentRoute === "/WalletSales" ||
                              currentRoute === "/TransactionReceipt" ||
                              currentRoute === "/WalletRecharge" ||
                              currentRoute === "/Store" ||
                              currentRoute === "/Categories" ||
                              currentRoute === "/Product" ||
                              currentRoute === "/CreateCategories" ||
                              currentRoute === "/CreateProducts" ||
                              currentRoute === "/ProductStock" ||
                              currentRoute === "/AddProductStock" ||
                              currentRoute === "/IssueCard"
                                ? "menu-name activeColorBlue"
                                : "menu-name"
                            }
                          >
                            Wallet
                          </div>
                        </div>

                        <div className="arrow">
                          {walletCheck ? (
                            <img src={down_arrow} alt="" />
                          ) : (
                            <img src={ic_blueUP_arrow} alt="" />
                          )}
                        </div>
                      </Link>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className={sidebar ? "sub-menus" : "none"}>
                        <Link
                          to="/Store"
                          className="sub-menue-items"
                          onClick={() => {
                            isMobile ? setSidebar(false) : setSidebar(true);
                          }}
                        >
                          {/* {permissions && permissions["Announcements"] && ( */}
                          <div
                            className={
                              currentRoute === "/Store"
                                ? "menu-name activeColorBlue"
                                : "menu-name"
                            }
                          >
                            Store
                          </div>
                          {/* )} */}
                        </Link>
                        <Link
                          to="/Categories"
                          className="sub-menue-items"
                          onClick={() => {
                            isMobile ? setSidebar(false) : setSidebar(true);
                          }}
                        >
                          {/* {permissions && permissions["Announcements"] && ( */}
                          <div
                            className={
                              currentRoute === "/Categories" ||
                              currentRoute === "/CreateCategories"
                                ? "menu-name activeColorBlue"
                                : "menu-name"
                            }
                          >
                            Categories
                          </div>
                          {/* )} */}
                        </Link>
                        <Link
                          to="/Product"
                          className="sub-menue-items"
                          onClick={() => {
                            isMobile ? setSidebar(false) : setSidebar(true);
                          }}
                        >
                          {/* {permissions && permissions["Announcements"] && ( */}
                          <div
                            className={
                              currentRoute === "/Product" ||
                              currentRoute === "/CreateProducts"
                                ? "menu-name activeColorBlue"
                                : "menu-name"
                            }
                          >
                            Products
                          </div>
                          {/* )} */}
                        </Link>
                        <Link
                          to="/ProductStock"
                          className="sub-menue-items"
                          onClick={() => {
                            isMobile ? setSidebar(false) : setSidebar(true);
                          }}
                        >
                          {/* {permissions && permissions["Announcements"] && ( */}
                          <div
                            className={
                              currentRoute === "/ProductStock" ||
                              currentRoute === "/AddProductStock"
                                ? "menu-name activeColorBlue"
                                : "menu-name"
                            }
                          >
                            Products Stock
                          </div>
                          {/* )} */}
                        </Link>
                        <Link
                          to="/IssueCard"
                          className="sub-menue-items"
                          onClick={() => {
                            isMobile ? setSidebar(false) : setSidebar(true);
                          }}
                        >
                          {/* {permissions && permissions["Announcements"] && ( */}
                          <div
                            className={
                              currentRoute === "/IssueCard"
                                ? "menu-name activeColorBlue"
                                : "menu-name"
                            }
                          >
                            Issue Card
                          </div>
                          {/* )} */}
                        </Link>
                        <Link
                          to="/EmpoloyeeWallet"
                          className="sub-menue-items"
                          onClick={() => {
                            isMobile ? setSidebar(false) : setSidebar(true);
                          }}
                        >
                          {/* {permissions && permissions["Announcements"] && ( */}
                          <div
                            className={
                              currentRoute === "/EmpoloyeeWallet" ||
                              currentRoute === "/RechargeWallet"
                                ? "menu-name activeColorBlue"
                                : "menu-name"
                            }
                          >
                            Employee Wallet
                          </div>
                          {/* )} */}
                        </Link>
                        <Link
                          to="/WalletSales"
                          className="sub-menue-items"
                          onClick={() => {
                            isMobile ? setSidebar(false) : setSidebar(true);
                          }}
                        >
                          {/* {permissions && permissions["Announcements"] && ( */}
                          <div
                            className={
                              currentRoute === "/WalletSales" ||
                              currentRoute === "/TransactionReceipt"
                                ? "menu-name activeColorBlue"
                                : "menu-name"
                            }
                          >
                            Wallet Sales
                          </div>
                          {/* )} */}
                        </Link>
                        <Link
                          to="/WalletRecharge"
                          className="sub-menue-items"
                          onClick={() => {
                            isMobile ? setSidebar(false) : setSidebar(true);
                          }}
                        >
                          {/* {permissions && permissions["Announcements"] && ( */}
                          <div
                            className={
                              currentRoute === "/WalletRecharge" ||
                              currentRoute === "/TransactionReceipt"
                                ? "menu-name activeColorBlue"
                                : "menu-name"
                            }
                          >
                            Wallet Recharges
                          </div>
                          {/* )} */}
                        </Link>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </div>
              ) : null}
              {/* ================> START Logout <============== */}
              <div
                className="main-logout"
                onClick={() => setLogoutModalShow(true)}
              >
                <div className="menus-items" id="logout">
                  <div className="icon-menu-name">
                    <div className="icon-left">
                      {Dark === "lightMode" ? (
                        <svg
                          width="25"
                          height="25"
                          viewBox="0 0 25 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.5 4.5082C13.5002 3.95592 13.0527 3.50803 12.5004 3.50781C11.9481 3.5076 11.5002 3.95514 11.5 4.50742L11.4968 12.5109C11.4966 13.0632 11.9442 13.511 12.4965 13.5113C13.0487 13.5115 13.4966 13.0639 13.4968 12.5117L13.5 4.5082Z"
                            fill="#111111"
                            fill-opacity="0.5"
                          />
                          <path
                            d="M4.5 13.4909C4.5 11.2818 5.39541 9.28186 6.84308 7.83415L8.2573 9.24837C7.17155 10.3341 6.5 11.8341 6.5 13.4909C6.5 16.8047 9.18629 19.4909 12.5 19.4909C15.8137 19.4909 18.5 16.8047 18.5 13.4909C18.5 11.8341 17.8284 10.3341 16.7426 9.2483L18.1568 7.83408C19.6046 9.2818 20.5 11.2818 20.5 13.4909C20.5 17.9092 16.9183 21.4909 12.5 21.4909C8.08172 21.4909 4.5 17.9092 4.5 13.4909Z"
                            fill="#111111"
                            fill-opacity="0.5"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="25"
                          height="25"
                          viewBox="0 0 25 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.5 4.5082C13.5002 3.95592 13.0527 3.50803 12.5004 3.50781C11.9481 3.5076 11.5002 3.95514 11.5 4.50742L11.4968 12.5109C11.4966 13.0632 11.9442 13.511 12.4965 13.5113C13.0487 13.5115 13.4966 13.0639 13.4968 12.5117L13.5 4.5082Z"
                            fill="#cccccc"
                            fill-opacity="1"
                          />
                          <path
                            d="M4.5 13.4909C4.5 11.2818 5.39541 9.28186 6.84308 7.83415L8.2573 9.24837C7.17155 10.3341 6.5 11.8341 6.5 13.4909C6.5 16.8047 9.18629 19.4909 12.5 19.4909C15.8137 19.4909 18.5 16.8047 18.5 13.4909C18.5 11.8341 17.8284 10.3341 16.7426 9.2483L18.1568 7.83408C19.6046 9.2818 20.5 11.2818 20.5 13.4909C20.5 17.9092 16.9183 21.4909 12.5 21.4909C8.08172 21.4909 4.5 17.9092 4.5 13.4909Z"
                            fill="#cccccc"
                            fill-opacity="1"
                          />
                        </svg>
                      )}
                    </div>
                    <div className="menu-name">Logout</div>
                  </div>
                </div>
              </div>
              {/* ================> END Logout <============== */}
            </Accordion>
          </div>
        </aside>
      </div>
    </main>
  );
};

export default Sidebar;
